import React from 'react'
import axios from '../../../helpers/request';
import './campaigndetail.css'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { DeleteOutlined, EditOutlined, VisibilityOutlined, AddOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

function CustomToolbar() {
  return (
    <span className='exportbutton'>
      <GridToolbarExport />
    </span>
  );
}

export default function EmailsTable({ campaignid }) {

  const [data, setData] = useState({
    data: [],
    total: 0
  });

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [disableExport, setDisableExport] = useState(false);

  const datacolumns = [
    "uniqueid",
    "email",
    "uid",
    "status",
    "datetimesent",
    "processed",
    "delivered",
    "opens",
    "clicks",
    "deferred",
    "dropped",
    "bounced"
  ];

  const objectToCsv = function (data) {

    const csvRows = [];

    /* Using push() method we push fetched
       data into csvRows[] array */
    csvRows.push(datacolumns.join(','));

    // Loop to get value of each objects key
    for (const row of data) {
      const values = datacolumns.map(header => {
        const val = row[header]
        if (header === "uniqueid" || header === "uid") {
          return `'${val}`;
        } else if (["processed", "delivered", "opens", "clicks", "deferred", "dropped", "bounced"].includes(header)) {
          return val ? 'Yes' : 'No';
        } else {
          return `${val}`;
        }
      });

      // To add, separator between each value
      csvRows.push(values.join(','));
    }

    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join('\n');
  };

  const download = function (data) {

    // Creating a Blob for having a csv file format  
    // and passing the data with type 
    const blob = new Blob([data], { type: 'text/csv' });

    // Creating an object for downloading url 
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML 
    const a = document.createElement('a')

    // Passing the blob downloading url  
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading 
    // and passing the download file name 
    a.setAttribute('download', 'emails_' + campaignid + '.csv');

    // Performing a download with click 
    a.click()
  }

  const exportAllEmails = () => {

    let searchParams = {
      page_number: -1,
      page_size: -1
    };
    setDisableExport(true);
    axios.get('/emails/' + campaignid, { params: searchParams }).then(async (response) => {
      console.log("response", response);
      if (response.status === 200 && response.data) {
        const content = objectToCsv(response.data);
        download(content);
        setDisableExport(false);
      } else {
        setDisableExport(false);
        throw new Error(response?.error);
      }
    });
  }


  const getSmsSends = (campaignid) => {
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    };
    axios.get('/emails/' + campaignid, { params: searchParams }).then(async (response) => {
      console.log("emails-response", response)
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }


  useEffect(() => {
    if (campaignid) {
      getSmsSends(campaignid)
    }
  }, [campaignid, pageSize, pageNumber])


  const columns = [
    { field: 'fromemail', headerName: 'From Email', flex: 1, minWidth: 150, sortable: false },
    { field: 'email', headerName: 'To Email', flex: 1, minWidth: 150, sortable: false },
    { field: 'uid', headerName: 'UID', flex: 1, minWidth: 150, sortable: false },
    { field: 'status', headerName: 'Status', flex: 1, sortable: false },
    {
      field: 'datetimesent', headerName: 'Date Sent', flex: 1, type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    {
      field: 'processed', headerName: 'Processed', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'delivered', headerName: 'Delivered', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'deferred', headerName: 'Deferred', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'opens', headerName: 'Openned', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'clicks', headerName: 'Clicked', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'bounced', headerName: 'Bounced', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
    {
      field: 'dropped', headerName: 'Dropped', flex: 1, sortable: false,
      valueGetter: ({ value }) => Number(value)
    },
  ];

  return (
    <div className='sentsTable'>
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Emails</h3>
      </div>
      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          Toolbar: () => {
            return (
              <div className='tableButtonRow'>
                <span className='exportbutton'>
                  <Button disabled={disableExport} className='exportbutton_other' color="primary" onClick={exportAllEmails} >Export All</Button>
                </span>
                <span className='exportbutton'>
                  <GridToolbarExport />
                </span>
              </div>
            );
          },
        }}
      />
    </div>
  )
}
