import "../NewContact/newcontact.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import axios from "../../../helpers/request";

import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Tooltip } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minwidth: '400px'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const filter = createFilterOptions();

export default function AllContacts(props) {
    const [loading, setLoading] = useState(0);
    const [allContacts, setAllContacts] = useState([]);
    const [allList, setAllList] = useState([]);
    const params = useParams();
    const location = useLocation()
    const history = useHistory();
    const [selectedList, setSelectedList] = useState({});
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getAllContacts();
    }, []);

    const getAllContacts = () => {
        axios.get(`/all-contacts`)
            .then((res) => {
                if (res.data) {
                    let allData = res.data;
                    let checkdup = [];
                    let tempAllList = [];
                    allData.map((i) => {
                        if (!checkdup.includes(i.listId)) {
                            checkdup.push(i.listId);
                            tempAllList.push({'list_id': i.listId, 'list_name': i.list_name});
                        }
                    });
                    console.log("allData => ", allData);
                    console.log("tempAllList => ", tempAllList);
                    setAllContacts(allData);
                    setAllList(tempAllList);
                }
            })
            .catch((err) => {
                console.error("errr", err);
            });
    };

    const handleDelete = (id, label) => {
        const confirmDelete = window.confirm(`Are you sure to delete this contact?`);
        if (confirmDelete) {
            axios.delete('/contact/' + id)
                .then(function (response) {
                    alert("Contact deleted successfully");
                    getAllContacts();
                })
                .catch(function (error) {
                    alert(error?.response?.data?.message || "Unable to delete contact");
                });
        }
    };

    let contactColumns = [
        {
            field: 'checkboxes',
            headerName: 'Select',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <div className='userListActions'>
                        <input type="checkbox" id={'row_id_'+row.id} checked={selectedContactIds.includes(row.id)} onClick={(event) => {
                            let tempclist = selectedContactIds;
                            if (event.target.checked) {
                                tempclist.push(row.id);
                            } else {
                                tempclist = tempclist.filter(e => e !== row.id)
                            }
                            console.log("tempclist => ", tempclist);
                            setSelectedContactIds(tempclist);
                        }} />
                    </div>
                )
            }
        },
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'first_name', headerName: 'First Name', width: 300 },
        { field: 'last_name', headerName: 'Last Name', width: 250 },
        { field: 'number', headerName: 'Number', width: 250 },
        { field: 'email', headerName: 'Email', width: 350 },
        { field: 'list_name', headerName: 'List Name', width: 200 },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <div className='userListActions'>
                        <Link to={{
                            pathname: '/contact-edit/' + row.id,
                            state: { listId: params.listId }
                        }}
                        >
                            <button className="actionElementButton">
                                <EditOutlined className='actionElementIcon' />
                                {/* <span className="actionButtonText">Edit</span> */}
                            </button>
                        </Link>
                        <DeleteOutlined className="userListDelete" onClick={() => handleDelete(row.id, row.first_name +' '+ row.last_name)} />
                    </div>
                )
            }
        }
    ];

    const handleCancelAction = () => {
        setOpenModal(false);
    }

    const handleSubmitAction = () => {
        if (selectedContactIds.length && selectedList) {
            let apiParams = {};
            apiParams.selectedList = selectedList;
            apiParams.selectedContactIds = selectedContactIds;

            axios.put("move-contacts-to-list", {
                ...apiParams
            }).then(res => {
                if (res.data) {
                    setOpenModal(false);
                    setSelectedContactIds([]);
                    setSelectedList({});
                    alert("All contacts moved to list successfully");
                    getAllContacts();
                } else {
                    alert("Something went wrong adding list!");
                }
                setLoading(false)
                // console.log(res.data)
            }).catch(err => {
                alert(err?.response?.data?.message || "Something went wrong adding list!");
                setLoading(false)
                console.error(err)
            })
        } else {
            alert("Select contact list from options");
        }
    }

    return (
        <div>
            <div className="text-end my-2">
                <button
                    onClick={() => selectedContactIds.length ? setOpenModal(true) : alert('Select some contacts for moving them')}
                    className="btn btn-primary text-white br-10"
                >Move Contacts to List</button>
                <button
                    onClick={() => {
                        history.push('/newContact', { listId: 'all_list', type: 'sms_email' })
                    }}
                    className="btn btn-primary text-white br-10 ml-15"
                >Add Contact</button>
            </div>
            <DataGrid
                rows={allContacts}
                columns={contactColumns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                autoHeight={true}
            />
            <BootstrapDialog
                onClose={handleCancelAction}
                aria-labelledby="customized-dialog-title-1"
                open={openModal}
                className="editcolumnModal"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="editcolumnModal22">
                    Move selected contacts to selected list
                </DialogTitle>
                <DialogContent dividers>
                    <label>Select List</label>
                    <Autocomplete
                            onChange={(event, newValue) => {
                                setSelectedList(newValue)
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={allList}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                return option.inputValue;
                                }
                                // Regular option
                                return option.list_name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.list_name}</li>}
                            sx={{ width: '100%' }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                </DialogContent>
                <DialogActions>
                    <button
                    onClick={handleSubmitAction}
                    className="btn btn-primary text-white br-10"
                    >Submit</button>
                    <button
                    onClick={handleCancelAction}
                    className="btn btn-primary text-white br-10"
                    >Cancel</button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}
