import "./newchannel.css";
import React, { useState, useEffect } from "react";
import { ScheduleOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "../../../helpers/request";
// import PasswordChecklist from "react-password-checklist"

import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import Select from 'react-select';
import { Breadcrumbs, Typography } from "@material-ui/core";


export default function NewChannel() {
  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [channelData, setData] = useState({
    sid: "",
    token: "",
    type: "",
    fromdata: "",
    label: ""
  })
  const [step, setStep] = useState(1)
  const [companyId, setUserID] = useState("")
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [messagingServices, setServices] = useState([])

  useEffect(() => {
    userDetail()
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    axios.post("add-channel", {
      ...channelData,
      companyId
    }).then(res => {
      if (res.data && res.data.affectedRows === 1) {
        history.push("/Channels")
      } else {
        alert("Something went wrong adding channel!");
      }
      setLoading(false)
      console.log(res.data)
    }).catch(err => {
      alert(err?.response?.data?.message || "Something went wrong adding channel!");
      setLoading(false)
      console.log(err)
    })
  }

  function userDetail() {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUserID(res.data[0].added_by ? res.data[0].added_by : res.data[0].id);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  console.log(channelData, companyId)

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/Channels">
          Channels
        </Link>
        <Typography color="text.primary">New channel</Typography>
      </Breadcrumbs>

      <div className="newUserItem">
        <label>
          Choose Channel Type <span className="mandatory">*</span>
        </label>
        <select
          name="channel"
          className="popupDropdown"
          onChange={e => {
            if (channelData.type !== e.target.value) {
              setData({
                type: e.target.value,
                sid: "",
                token: "",
                fromdata: "",
                label: ""
              })
              setPhoneNumbers([])
              setServices([])
              setStep(1)
            }
          }}
          value={channelData.type}
        // disabled={channelData.type}
        >
          <option disabled selected value="">Select channel type</option>
          {[{
            label: "SMS with Twilio Messaging Service",
            value: "twilio-messaging-service"
          }, {
            label: "SMS (with Twilio)",
            value: "twilio-sms"
          }, {
            label: "Voice (with Twilio)",
            value: "twilio-voice"
          }, {
            label: "Sendgrid Mail service",
            value: "sendgrid-mail-service"
          }, {
            label: "WhatsApp with Twilio",
            value: "whatsapp"
          }]
            .map((channel) => {
              return (
                <option key={channel.value} value={channel.value}>
                  {channel.label}
                </option>
              );
            })}
        </select>
      </div>
      {["twilio-messaging-service", "twilio-sms", "twilio-voice" , "whatsapp"].includes(channelData.type) &&
        <>
          <div className="newUserItem">
            <label>
              Account SID <span className="mandatory">*</span>
            </label>
            <input
              name="sid"
              type="text"
              disabled={step !== 1}
              onChange={e => setData({
                ...channelData,
                sid: e.target.value
              })}
              value={channelData.sid}
              placeholder="Enter the Account SID"
            />
          </div>
          <div className="newUserItem">
            <label>
              Auth token <span className="mandatory">*</span>
            </label>
            <input
              name="token"
              type="text"
              disabled={step !== 1}
              onChange={e => setData({
                ...channelData,
                token: e.target.value
              })}
              value={channelData.token}
              placeholder="Enter the users Auth token"
            />
          </div>
          {(step === 2) && ["twilio-sms", "whatsapp" ,"twilio-voice"].includes(channelData.type) &&
            <>
              <div className="newUserItem" style={{ marginBottom: "10px" }}>
                <label>
                  Choose Phone number <span className="mandatory">*</span>
                </label>
                <select
                  name="fromdata"
                  className="popupDropdown"
                  onChange={e => {
                    let data = e.target.value.split(" - ")
                    let fromdata = data.length ? data[1] : ""
                    let label = data.length ? data[0] : ""
                    setData({
                      ...channelData,
                      fromdata,
                      label
                    })
                  }}
                  value={channelData.fromdata ? channelData.label + " - " + channelData.fromdata : ""}
                >
                  <option disabled selected value="">{channelData.type !== "whatsapp" ? "Select Phone number" : "Select whatsapp enabled number"}</option>
                  {phoneNumbers.map(v => ({
                    label: v.friendlyName + " ( " + v.phoneNumber + " )",
                    value: v.friendlyName + " - " + v.phoneNumber
                  }))
                    .map((channel) => {
                      return (
                        <option key={channel.value} value={channel.value}>
                          {channel.label}
                        </option>
                      );
                    })}
                </select>

                <span style={{ display: "flex", justifyContent: "end" }}>
                  <a href="https://www.twilio.com/console/phone-numbers/incoming">Purchase a new number</a>
                </span>
              </div>
              <div className="newUserItem">
                <label>
                  Label <span className="mandatory">*</span>
                </label>
                <input
                  name="label"
                  type="text"
                  onChange={e => {
                    setData({
                      ...channelData,
                      label: e.target.value
                    })
                  }}
                  value={channelData.label}
                  placeholder="Label"
                />
              </div>

              {channelData.type === "whatsapp" && <div className="newUserItem">
                <p>
                  Please complete below additional setup on Twilio's website to link
                  WhatsApp communication with Pronto.
                </p>
                <ul class="ms-4 mb-4">
                  <li>
                    Go to, Twilio's&nbsp;
                    <a href="https://www.twilio.com/console/sms/whatsapp/senders" target="_blank">WhatsApp Enabled Senders</a>
                    &nbsp;
                    page.
                  </li>
                  <li>Click on "Edit Sender" for the number you connected on Twilio.</li> <li>Under "How would you like to configure this sender?", select "Use webhooks".</li> <li>
                    Copy and paste the below Webhook URL value in the text boxes. Leave the drop-down list values as
                    <em>"HTTP Post"</em>.
                  </li>
                </ul>
                <div class="form-group">
                  <label for="Email-Webhook-url" class="col-form-label">Webhook URL for incoming messages
                  </label>
                  <div className="newUserItem">
                    <input
                      name="Email-Webhook-url"
                      type="text"
                      value={process.env.REACT_APP_WHATSAPP_WEBHOOK_URL}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>}
            </>
          }

          {(step === 2) && (channelData.type == "twilio-messaging-service") &&
            <>
              <div className="newUserItem">
                <label>
                  Choose Messaging Service <span className="mandatory">*</span>
                </label>
                <select
                  name="fromdata"
                  className="popupDropdown"
                  onChange={e => {
                    let data = e.target.value.split(" - ")
                    let fromdata = data.length ? data[1] : ""
                    let label = data.length ? data[0] : ""
                    setData({
                      ...channelData,
                      fromdata,
                      label
                    })
                  }}
                  value={channelData.fromdata ? channelData.label + " - " + channelData.fromdata : ""}
                >
                  <option disabled selected value="">Select messaging service</option>
                  {messagingServices.map(v => ({
                    label: v.friendlyName + " ( " + v.sid + " )",
                    value: v.friendlyName + " - " + v.sid
                  }))
                    .map((channel) => {
                      return (
                        <option key={channel.value} value={channel.value}>
                          {channel.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="newUserItem">
                <label>
                  Label <span className="mandatory">*</span>
                </label>
                <input
                  name="label"
                  type="text"
                  placeholder="Label"
                  onChange={e => {
                    setData({
                      ...channelData,
                      label: e.target.value
                    })
                  }}
                  value={channelData.label}
                />
              </div>
            </>}
          {step === 1 && <div className="newUserItem">
            <button
              disabled={loading}
              className="newChannelButton align-self-end"
              onClick={() => {
                axios.post(["twilio-sms", "whatsapp","twilio-voice"].includes(channelData.type) ? "twilio-account-numbers" : "twilio-account-services", {
                  data: {
                    twilioSid: channelData.sid,
                    twilioToken: channelData.token
                  }
                }).then(res => {
                  console.log('res.data' , res.data)
                  if (["twilio-sms", "whatsapp",'twilio-voice'].includes(channelData.type) && res.data && res.data.incomingPhoneNumbers) {
                    setPhoneNumbers(res.data.incomingPhoneNumbers)
                    setStep(2)
                  } else if (res.data && res.data.services) {
                    setServices(res.data.services)
                    setStep(2)
                    console.log('res.data.services', res.data.services)
                  }
                }).catch(err => {
                  alert(err?.response?.data?.message || "Something went wrong fetching numbers!");
                  console.log(err)
                })
              }}
            >
              Next
            </button>
          </div>}

        </>}
      {(channelData.type === "sendgrid-mail-service") && <>
        <div className="newUserItem">
          <label>
            Sendgrid API Key <span className="mandatory">*</span>
          </label>
          <input
            name="token"
            type="text"
            onChange={e => setData({ ...channelData, token: e.target.value, sid: e.target.value })}
            value={channelData.token}
            placeholder="Enter the Sendgrid API Key"
          />
        </div>
        <div className="newUserItem">
          <label>
            From Email <span className="mandatory">*</span>
          </label>
          <input
            name="fromdata"
            type="text"
            onChange={e => setData({ ...channelData, fromdata: e.target.value })}
            value={channelData.fromdata}
            placeholder="Enter the from email"
          />
        </div>
        <div className="newUserItem">
          <label>
            From Name <span className="mandatory">*</span>
          </label>
          <input
            name="label"
            type="text"
            onChange={e => setData({ ...channelData, label: e.target.value })}
            value={channelData.label}
            placeholder="Enter the from name"
          />
        </div>
        <div className="newUserItem">
          <p>
            Please complete below additional setup on the Sendgrid <a href="https://sendgrid.com/" target="_blank">website</a> to link
            Sendgrid mail channel with Pronto.
          </p>
          <ul class="ms-4 mb-4">
            <li>
              Go to, Sendgrid's&nbsp;
              <a href="https://app.sendgrid.com/settings/mail_settings/webhook_settings" target="_blank">mail settings and then click on event webhooks</a>
              &nbsp;
              page.
            </li>
            <li>Click on "Create new webhook" and fill in teh firendly name.</li> <li>
              Copy and paste the below webhook value in to the Post URL box.
            
            </li>
            <li>Select all options under Engagement data and Deliverability Data, then click on save</li>
          </ul>
          <div class="form-group">
            <label for="Email-Webhook-url" class="col-form-label">Webhook URL for incoming email stats.
            </label>
            <div className="newUserItem">
              <input
                name="Email-Webhook-url"
                type="text"
                value={process.env.REACT_APP_EMAIL_WEBHOOK_URL}
                readOnly
                disabled
              />
            </div>
          </div>
        </div>
      </>}


      {
        ((step === 2) || (channelData.type === "sendgrid-mail-service")) && <div className="newUserItem">
          <button
            className="newChannelButton align-self-end"
            disabled={(Object.keys(channelData).filter(v => !v).length > 0) || !companyId}
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      }
    </div >
  );
}
