import React, { useState, useEffect, useRef } from "react";
import "./sms.css";
import axios from "../../../helpers/request";

import Switch from "@material-ui/core/Switch";
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { Grid, Autocomplete, TextField, DialogContent, Dialog } from '@mui/material';

import { DateTime as LuxonDateTime } from "luxon";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useAppState } from "../../../state";
import CustomPopup from "../../Popup/CustomPopup";
import { alltimeZones } from "../../common/timezones";
import templatesIcon from "../../images/templates.svg";
import { ckeditorConfig } from "../../common/ckeditorConfig";
import Importcontacts from "../Contacts/Import/ImportContacts";

const SEND_THROUGH_EMAIL_VALUE = "email";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      minwidth: '400px'
  },
  '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
  },
}));

const greetings = [
  { label: 'No Greetings', value: 'Nogreet' },
  { label: "Hi @FirstName", value: "greetHiFirst" },
  { label: "Hi @FirstName @LastName", value: "greetHiFirstLast" },
  { label: "Hello @FirstName", value: "greetHelloFirst" },
  { label: "Hello @FirstName @LastName", value: "greetHelloFirstLast" },
];
const GREET_DEFAULT_VALUE = greetings[0].value;

const currentDateCondition = {'minutes': 16};
const minDateCondition = {'minutes': 15};
const maxDateCondition = {'days': 7};

const timeObject = LuxonDateTime.local();
const defaultScheduleMinDateTime = timeObject.plus(minDateCondition);
const defaultScheduleMaxDateTime = timeObject.plus(maxDateCondition);
const defaultScheduleDateTime = timeObject.plus(currentDateCondition);

export default function ScheduleEmail() {
  let defaultScheduleUTCDateTime = LuxonDateTime.local().setZone('UTC');
  defaultScheduleUTCDateTime = defaultScheduleUTCDateTime.plus(currentDateCondition);

  const [campaign, setCampaign] = useState({
    isBoth: false,
    isBulk: false,
    campaignName: "",
    dateToSend: "",
    smsMessageBody: "",
    messageBody: "",
    file: [],
    greetingText: GREET_DEFAULT_VALUE,
  });

  const fileInputRef = useRef();

  const [channel, setChannel] = useState("");
  const [smsChannel, setSmsChannel] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [smsButtonPopup, setSmsButtonPopup] = useState(false);

  const [data, setData] = useState([]);
  const [template, setTemplate] = useState("");
  const [smsTemplate, setSmsTemplate] = useState("");
  const [sending, setSending] = useState(false);
  const [opencontactModal, setOpencontactModal] = useState(false);
  const [updateModalSize, setUpdateModalSize] = useState(false);
  const [emailEditor, setEmailEditor] = useState("");
  const { user: loggedUser } = useAppState();

  const [userSettings, setUserSettings] = useState(false);
  const [isEmailTemplateApplied, setIsEmailTemplateApplied] = useState(false);
  const [isSMSTemplateApplied, setIsSMSTemplateApplied] = useState(false);
  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id;

  const [scheduleMinDateTime, setScheduleMinDateTime] = useState(defaultScheduleMinDateTime);
  const [scheduleMaxDateTime, setScheduleMaxDateTime] = useState(defaultScheduleMaxDateTime);
  const [currentTimezone, setCurrentTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [storedUTCDateValue, setStoredUTCDateValue] = useState(defaultScheduleUTCDateTime);
  const [displayDateValue, setDisplayDateValue] = useState(defaultScheduleDateTime);

  let  defaultTimezone = alltimeZones.filter(row=>row.label === currentTimezone);
  defaultTimezone = defaultTimezone[0];

  const getChannelList = (companyId) => {
    axios.get(`/all-channels/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        setChannelList(response.data);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const loadCKEditor = () => {
    if (window && document) {
      let ckEditorDiv = document.getElementsByClassName('ck-editor');
      if (!ckEditorDiv.length) {
        window.CKEDITOR.ClassicEditor.create(document.getElementById("super-ckeditor"), ckeditorConfig).then( editor => {
          window.editor = editor;
          setEmailEditor(editor);
        })
        .catch( error => {
            console.log("error of ckeditor create=>", error);
            loadCKEditor();
        });
      }
    }
  }

  if (campaign.isBoth && smsChannel && channel) {
    loadCKEditor();
  }

  useEffect(() => {
    axios.get(`/get-settings/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        let settings = response.data;
        setUserSettings(settings);
      } else {
        throw new Error(response?.error);
      }
    });

    axios
      .post(`/userdetails`)
      .then((res) => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by
            ? res.data[0].added_by
            : res.data[0].id;
          getChannelList(cmpny);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, []);

  const openPopup = (event, smsTemp) => {
    event.preventDefault();
    setData([]);
    setTemplate("");
    getTemplates();
    if (smsTemp) {
      setSmsButtonPopup(true);
    } else {
      setButtonPopup(true);
    }
  };

  const getTemplates = () => {
    let queryParams = {
      companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
    };
    axios.get("/templates", { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        const templates = response.data;
        setData(templates);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const applyTemplate = (e, smsTemp) => {
    const selectedTemplate = data.filter(
      (temp) => temp.id == (smsTemp ? smsTemplate : template)
    );

    if (selectedTemplate && selectedTemplate[0]) {
      if (smsTemp) {
        setIsSMSTemplateApplied(true);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          smsMessageBody: selectedTemplate[0].templatebody,
        }));
      } else {
        setIsEmailTemplateApplied(true);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          messageBody: selectedTemplate[0].templatebody,
        }));
        emailEditor.setData(selectedTemplate[0].templatebody);
        if (!userSettings.enable_email_template_edit) {
          document.getElementsByClassName('ck-editor')[0].style.pointerEvents = "none";
          var el = document.querySelectorAll('.ck-widget__type-around__button');
          for (var i=0;i<el.length;i++) {
            el[i].style.display = 'none';
          }
        }
      }
    }

    setButtonPopup(false);
    setSmsButtonPopup(false);
  };

  const removeAppliedTemplate = type => {
    if (type == 'sms') {
      setIsSMSTemplateApplied(false);
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        smsMessageBody: '',
      }));
    } else {
      setIsEmailTemplateApplied(false);
      emailEditor.setData('');
      document.getElementsByClassName('ck-editor')[0].style.pointerEvents = "auto";
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        messageBody: '',
      }));
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: value,
    }));
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    if (name !== "isBoth") {
      if (checked) { setOpencontactModal(true); }
    }
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: checked,
    }));
    if (!checked && name == "isBoth") {
      setChannel("");
    }
  };

  const resetForm = () => {
    if (emailEditor) { emailEditor.setData(''); }
    setIsSMSTemplateApplied(false);
    setIsEmailTemplateApplied(false);
    setCampaign({
      campaignName: "",
      messageBody: "",
      smsMessageBody: "",
      file: [],
      isBoth: false,
      dateToSend: "",
      greetingText: GREET_DEFAULT_VALUE,
    });
    setSelectedContactList([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isValidData = () => {
    const {
      campaignName,
      //messageBody,
      //smsMessageBody,
      isBoth,
    } = campaign;
    let isValid = true;

    let errors = [];
    if (smsChannel === "") {
      isValid = false;
      errors[errors.length] = "Please select SMS channel";
    }

    if (smsChannel) {
      if (campaignName === "") {
        isValid = false;
        errors[errors.length] = "Campaign name is required";
      }

      if (selectedContactList.length === 0) {
        isValid = false;
        errors[errors.length] = "Please upload contacts";
      }

      /*if (smsMessageBody === "") {
        isValid = false;
        errors[errors.length] = "SMS body is required";
      }*/

      if (!isSMSTemplateApplied) {
        isValid = false;
        errors[errors.length] = "SMS template is required";
      }
    }

    if (isBoth) {
      if (channel === "") {
        isValid = false;
        errors[errors.length] = "Please select email channel";
      } else {

        /*if (messageBody === "") {
          isValid = false;
          errors[errors.length] = "Email body is required";
        }*/

        if (!isEmailTemplateApplied) {
          isValid = false;
          errors[errors.length] = "Email template is required";
        }
      }
    }

    return { isValid, errors };
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let ckcontent = "";
    if (campaign.isBoth && smsChannel && channel) {
      ckcontent = emailEditor.getData().trim();
    }

    const { isValid, errors } = isValidData();
    if (isValid) {
      if (campaign.isBoth && smsChannel && channel) {
        ckcontent = new DOMParser().parseFromString(ckcontent, "text/html");
        ckcontent.querySelectorAll(".ck-widget__resizer").forEach(el => el.remove());
        ckcontent.querySelectorAll(".ck-reset_all").forEach(el => el.remove());
        ckcontent.querySelectorAll(".ck-widget__selection-handle").forEach(el => el.remove());
        campaign.messageBody = ckcontent.documentElement.innerHTML;
      }

      campaign.dateToSend = storedUTCDateValue.c.year+'-'+storedUTCDateValue.c.month+'-'+storedUTCDateValue.c.day+' '+storedUTCDateValue.c.hour+':'+storedUTCDateValue.c.minute+':'+storedUTCDateValue.c.second;
      campaign.dateToSendTimestamp = Math.round(storedUTCDateValue.ts/1000);
      campaign.dateToSendFullDate = storedUTCDateValue.toJSDate();
      const campaigndata = { ...campaign };
      setSending(true);

      const currentSmsChannel = channelList.filter((v) => v.id == smsChannel);
      const currentSmsChannelData = currentSmsChannel.length ? currentSmsChannel[0] : null;
      const { fromdata: fromNumber, sid: smsAccountSID, token: smsAuthToken } = currentSmsChannelData;

      let fromEmail, fromName, emailToken;
      if (campaign.isBoth) {
        const currentEmailChannel = channelList.filter((v) => v.id == channel);
        const currentEmailChannelData = currentEmailChannel.length ? currentEmailChannel[0] : null;
        const { fromdata, label, token } = currentEmailChannelData;
        fromEmail = fromdata;
        fromName = label;
        emailToken = token;
      }

      axios.post('/schedule-sms-email-channel', {
        data: {
          ...campaigndata,
          emailMessageBody: campaign.messageBody,
          contactList: selectedContactList,
          fromNumber,
          smsAccountSID,
          smsAuthToken,
          fromEmail,
          fromName,
          emailToken,
          companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
        },
        actionBy: loggedUser.fullName
      }).then(function (response) {
        setSending(false);
        alert(`Scheduled SMS${campaign.isBoth ? " and emails" : ""} successfully`);
        resetForm();
      }).catch(function (error) {
        setSending(false);
        console.log(error)
        alert("Unable to process this request");
      });
    } else {
      let errMsg = "Followings are errors in the form \n";
      errors.map((er) => {
        errMsg += "     - "+er+"\n";
      });
      alert(errMsg);
    }
  };

  const handleImportModalclose = () => {
    if (selectedContactList.length < 1) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        ["isBulk"]: false,
      }));
    }
    setOpencontactModal(false);
  }

  const getUploadedContactData = (dataArray) => {
    setOpencontactModal(false);
    setSelectedContactList(dataArray);
  }

  return (
    <div className="mainContentWrapper">
      <Grid container spacing={2} className="ptb-0 main-grid-container">
        <Grid container item xs={12} md={12} lg={12} spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <div className="sendNowItem">
              <label>Please select a channel</label>
              <select
                value={smsChannel}
                className="popupDropdown"
                onChange={(event) => {
                  setSmsChannel(event.target.value);
                  resetForm();
                }}
              >
                <option value="" disabled>
                  Select channel
                </option>
                {channelList
                  .filter((v) =>
                    ["twilio-messaging-service", "twilio-sms"].includes(v.type)
                  )
                  .map((chan) => (
                    <option key={chan.id} value={chan.id}>
                      {chan.label}
                    </option>
                  ))}
              </select>
            </div>
          </Grid>

          {smsChannel && (
            <Grid item xs={12} md={6} lg={6}>
              <div className="sendNowItem">
                <nobr>
                  { campaign.isBoth &&
                    <>
                      <span className="form-label">
                        Please select email sender
                      </span>
                      <span className="mandatory">*</span>
                    </>
                  }
                  <span className={!campaign.isBoth ? "pt-47 flex-row float-right form-label" : "flex-row float-right form-label" }>
                    {!campaign.isBoth ? 'Send Both Email/SMS' : '' }
                    <Switch
                      checked={campaign.isBoth}
                      onChange={handleChangeSwitch}
                      name="isBoth"
                    />
                  </span>
                </nobr>
                { campaign.isBoth &&
                  <select
                    value={channel}
                    id="smsdrop"
                    className="popupDropdown mt--5"
                    onChange={(event) => {
                      setChannel(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select channel
                    </option>
                    {channelList
                      .map((chan) => {
                          if (chan.type === "sendgrid-mail-service") {
                            return (
                              <option key={chan.id} value={chan.id}>
                                {chan.label}
                              </option>
                            )
                          }
                        }
                      )}
                  </select>
                }
              </div>
            </Grid>
          )}

          {/* SMS body and inputs */}
          { smsChannel && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2} className="sub-container hidden-container ptb-0">
                <Grid item xs={12} md={12} lg={12} className="mt-25">
                  <center>
                    <u>
                      <h5>
                        Send Details
                      </h5>
                    </u>
                  </center>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className="mt-25">
                  <div className="sendNowItem">
                    <nobr>
                      <span className="form-label">{campaign.isBoth?"Campaign Name  / Email Subject":"Campaign Name"}</span>
                      <span className="mandatory">*</span>
                      <span className="flex-row float-right form-label">
                        Upload Contacts
                        <Switch
                          checked={campaign.isBulk ? "checked" : ""}
                          onChange={handleChangeSwitch}
                          name="isBulk"
                        />
                      </span>
                    </nobr>
                    <input
                      value={campaign.campaignName}
                      onChange={handleChange}
                      name="campaignName"
                      type="text"
                      placeholder="Give your campaign a unique name"
                      className="nameimage pl-60"
                    />
                  </div>
                </Grid>

                { (selectedContactList.length >= 1) &&
                  <Grid item xs={12} md={12} lg={12}>
                    <div className="contacts-selected-div">
                      <span className="form-label border-box">
                        {selectedContactList.length} contacts selected 
                        <CheckIcon color="success" className="ml-15" />
                      </span>
                    </div>
                  </Grid>
                }

                <Grid item xs={12} md={12} lg={12}>
                  <div className="templateButtonContainer">
                    <label className="d-flex justify-content-between w-100">
                      <label className="form-label pt-35 pb-15">
                        Enter SMS Body Text <span className="mandatory">*</span>
                      </label>
                      <div className="select_template_div pt-10">
                        { !isSMSTemplateApplied ? 
                          (<button
                            className="templateElementButton template-button"
                            onClick={(e) => openPopup(e, true)}
                          >
                            <img src={templatesIcon} className="filter-red" />
                            <span className="templateButtonText">
                              Select Template
                            </span>
                          </button>) :
                          (<button
                            className="btn btn-white-red mr-20"
                            onClick={() => removeAppliedTemplate('sms')}
                          >
                            <span className="templateButtonText">
                              Remove Template
                            </span>
                          </button>)
                        }
                      </div>
                    </label>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <textarea
                      value={campaign.smsMessageBody}
                      onChange={handleChange}
                      name="smsMessageBody"
                      placeholder="Enter the body of your SMS message here"
                      disabled={isSMSTemplateApplied && !userSettings.enable_sms_template_edit}
                    ></textarea>
                  </div>
                </Grid>

                { (!campaign.isBoth || (campaign.isBoth && channel === "")) ? (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="sendNowItem">
                        <label>
                          Schedule Date /Time <span className="mandatory">*</span>
                        </label>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="sendNowItem">
                        <Autocomplete
                          id="grouped-demo"
                          options={alltimeZones}
                          groupBy={(option) => option.group}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => <TextField {...params} />}
                          value={defaultTimezone}
                          onChange={(event, newValue) => {
                            if (newValue !== null && newValue!== '') {
                              setCurrentTimezone(newValue.label);
                              let newTimezone = LuxonDateTime.local().setZone(newValue.label);
                              newTimezone = newTimezone.plus(currentDateCondition);
                              let utcTimezone = LuxonDateTime.local().setZone('UTC');
                              utcTimezone = utcTimezone.plus(currentDateCondition);
                              let newMinDate = LuxonDateTime.local().setZone(newValue.label);
                              newMinDate = newMinDate.plus(minDateCondition);
                              let newMaxDate = LuxonDateTime.local().setZone(newValue.label);
                              newMaxDate = newMaxDate.plus(maxDateCondition);
                              setDisplayDateValue(newTimezone);
                              setStoredUTCDateValue(utcTimezone);
                              setScheduleMinDateTime(newMinDate);
                              setScheduleMaxDateTime(newMaxDate);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="muidatepicker" style={{width:'96%'}}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DemoContainer components={['DateTimePicker']}>
                            <MuiDatePicker
                              value={displayDateValue}
                              minDateTime={scheduleMinDateTime}
                              maxDateTime={scheduleMaxDateTime}
                              onChange={newValue => {
                                let sdate = new Date(displayDateValue.c.year+'-'+displayDateValue.c.month+'-'+displayDateValue.c.day+' '+displayDateValue.c.hour+':'+displayDateValue.c.minute+':'+displayDateValue.c.second);
                                let edate = new Date(newValue.c.year+'-'+newValue.c.month+'-'+newValue.c.day+' '+newValue.c.hour+':'+newValue.c.minute+':'+newValue.c.second);
                                let diff =(edate.getTime() - sdate.getTime()) / 1000;
                                diff /= 60;
                                let diffInMinutes = Math.abs(Math.round(diff));
                                let newutcValue = "";
                                if (sdate.getTime() >= edate.getTime()) {
                                  newutcValue = storedUTCDateValue.minus({'minutes': diffInMinutes});
                                } else {
                                  newutcValue = storedUTCDateValue.plus({'minutes': diffInMinutes});
                                }
                                setDisplayDateValue(newValue);
                                setStoredUTCDateValue(newutcValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider></div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} className="pb-25">
                      <div className="templateButtonContainer flex-row-reverse mr-5">
                        <button
                          disabled={sending}
                          className="sendNowButton"
                          onClick={handleSubmit}
                        >
                          Schedule Now
                        </button>
                      </div>
                    </Grid>
                  </>
                  ) : ''
                }
              </Grid>
            </>
          )}
          {/* SMS body and inputs end*/}

          {/* Email body and inputs */}
          { campaign.isBoth && channel && (
            <Grid container item xs={12} md={12} lg={12} spacing={2} className="sub-container hidden-container ptb-0 mt-50">
              <Grid item xs={12} md={12} lg={12} className="mt-25">
                <center>
                  <u>
                    <h5>
                      Email details
                    </h5>
                  </u>
                </center>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className="mt-25 pb-25">
                <div className="sendNowItem mb-16">
                  <label>
                    Choose Greetings <span className="mandatory">*</span>
                  </label>
                  <select
                    value={campaign.greetingText}
                    onChange={handleChange}
                    name="greetingText"
                    className="popupDropdown"
                  >
                    <option value="" disabled>
                      Select Greeting
                    </option>
                    {greetings.map((greet) => (
                      <option key={greet.value} value={greet.value}>
                        {greet.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div className="sendNowItem">
                  <div className="templateButtonContainer mb-0">
                    <label className="d-flex justify-content-between w-100">
                      <label className="pt-25">
                        Enter Email Body Text{" "}
                        <span className="mandatory">*</span>
                      </label>
                      {!isEmailTemplateApplied ? 
                        (<button
                          className="templateElementButton template-button"
                          onClick={openPopup}
                        >
                          <img src={templatesIcon} className="filter-red" />
                          <span className="templateButtonText">
                            Select Template
                          </span>
                        </button>) : 
                        (<button
                          className="btn btn-white-red mr-20"
                          onClick={() => removeAppliedTemplate('email')}
                        >
                          <span className="templateButtonText">
                            Remove Template
                          </span>
                        </button>)
                      }
                    </label>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className="ckeditor-grid mb--40" sx={{paddingTop:'40px'}}>
                <div id="super-ckeditor"></div>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <div className="sendNowItem">
                  <label>
                    Schedule Date /Time <span className="mandatory">*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="sendNowItem">
                  <Autocomplete
                    id="grouped-demo"
                    options={alltimeZones}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} />}
                    value={defaultTimezone}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue!== '') {
                        setCurrentTimezone(newValue.label);
                        let newTimezone = LuxonDateTime.local().setZone(newValue.label);
                        newTimezone = newTimezone.plus(currentDateCondition);
                        let utcTimezone = LuxonDateTime.local().setZone('UTC');
                        utcTimezone = utcTimezone.plus(currentDateCondition);
                        let newMinDate = LuxonDateTime.local().setZone(newValue.label);
                        newMinDate = newMinDate.plus(minDateCondition);
                        let newMaxDate = LuxonDateTime.local().setZone(newValue.label);
                        newMaxDate = newMaxDate.plus(maxDateCondition);
                        setDisplayDateValue(newTimezone);
                        setStoredUTCDateValue(utcTimezone);
                        setScheduleMinDateTime(newMinDate);
                        setScheduleMaxDateTime(newMaxDate);
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="muidatepicker" style={{width:'96%'}}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DemoContainer components={['DateTimePicker']}>
                      <MuiDatePicker
                        value={displayDateValue}
                        minDateTime={scheduleMinDateTime}
                        maxDateTime={scheduleMaxDateTime}
                        onChange={newValue => {
                          let sdate = new Date(displayDateValue.c.year+'-'+displayDateValue.c.month+'-'+displayDateValue.c.day+' '+displayDateValue.c.hour+':'+displayDateValue.c.minute+':'+displayDateValue.c.second);
                          let edate = new Date(newValue.c.year+'-'+newValue.c.month+'-'+newValue.c.day+' '+newValue.c.hour+':'+newValue.c.minute+':'+newValue.c.second);
                          let diff =(edate.getTime() - sdate.getTime()) / 1000;
                          diff /= 60;
                          let diffInMinutes = Math.abs(Math.round(diff));
                          let newutcValue = "";
                          if (sdate.getTime() >= edate.getTime()) {
                            newutcValue = storedUTCDateValue.minus({'minutes': diffInMinutes});
                          } else {
                            newutcValue = storedUTCDateValue.plus({'minutes': diffInMinutes});
                          }
                          setDisplayDateValue(newValue);
                          setStoredUTCDateValue(newutcValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider></div>
              </Grid>

              <Grid item xs={12} md={12} lg={12} className="pb-25">
                <div className="templateButtonContainer flex-row-reverse mr-5">
                  <button
                    disabled={sending}
                    className="sendNowButton"
                    onClick={handleSubmit}
                  >
                    Schedule Now
                  </button>
                </div>
              </Grid>
            </Grid>
          )}
          {/* Email body and inputs END*/}
        </Grid>
      </Grid>

      {smsChannel && (
        <>
          <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h3 className="popupTitle">Please select your SMS Template</h3>
            <select
              value={template}
              onChange={(event) => setTemplate(event.target.value)}
              className="popupDropdown"
            >
              <option value="" disabled>
                Select Template
              </option>
              {data
                .filter(
                  (template) => template.type === SEND_THROUGH_EMAIL_VALUE
                )
                .map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.templatename}
                  </option>
                ))}
            </select>
            <button
              disabled={template === ""}
              onClick={applyTemplate}
              className="sendNowButton"
            >
              Submit
            </button>
          </CustomPopup>
          <CustomPopup trigger={smsButtonPopup} setTrigger={setSmsButtonPopup}>
            <h3 className="popupTitle">Please select your message template</h3>
            <select
              value={smsTemplate}
              className="popupDropdown"
              onChange={(event) => setSmsTemplate(event.target.value)}
            >
              <option value="" disabled>
                Select SMS Template
              </option>
              {data
                .filter(
                  (template) => template.type !== SEND_THROUGH_EMAIL_VALUE
                )
                .map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.templatename}
                  </option>
                ))}
            </select>
            <button
              className="sendNowButton"
              disabled={smsTemplate === ""}
              onClick={(e) => applyTemplate(e, true)}
            >
              Apply
            </button>
          </CustomPopup>
        </>
      )}

      <BootstrapDialog
          onClose={handleImportModalclose}
          aria-labelledby="customized-dialog-title-1"
          open={opencontactModal}
          className={updateModalSize ? "importContactInModal extendModalSize" : "importContactInModal " }
      >
        <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} spacing={2} className="dialogTitle-component import-contact-header-grid popup-inner">
                Upload Contacts
                <button id="mypopup" className="popupElementButton close-btn" onClick={handleImportModalclose}>
                  <span className="templateButtonText">X</span>
                </button>
              </Grid>
              <Grid item xs={12} md={12} lg={12} spacing={2}>
                <Importcontacts 
                  componentCalledSource={campaign.isBoth ? "sms_emailChannel" : "smsChannel"}
                  getUploadedContactData={getUploadedContactData}
                  setUpdateModalSize={setUpdateModalSize}
                />
              </Grid>
            </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
