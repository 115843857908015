import './channel.css'
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ScheduleOutlined, KeyboardReturnOutlined } from '@material-ui/icons';
import axios from '../../../helpers/request';
import { Breadcrumbs, Typography } from '@material-ui/core';

export default function ChannelDetails() {
  const [channelData, setData] = useState({
    sid: "",
    token: "",
    label: "",
    type: "",
    fromdata: ""
  });

  const { channelId } = useParams();

  const [adding, setAdding] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [messagingServices, setServices] = useState([])

  const getChannelDetails = (channelId) => {
    axios.get('/channel/' + channelId).then(async (response) => {
      if (response.status === 200 && response.data && response.data.id) {
        setData(response.data)
        if (response.data.type !== "sendgrid-mail-service") {
          axios.post(["twilio-sms", "whatsapp"].includes(response.data.type) ? "twilio-account-numbers" : "twilio-account-services", {
            data: {
              twilioSid: response.data.sid,
              twilioToken: response.data.token
            }
          }).then(res => {
            console.log(res.data)
            if (["twilio-sms", "whatsapp"].includes(response.data.type) && res.data && res.data.incomingPhoneNumbers) {
              setPhoneNumbers(res.data.incomingPhoneNumbers)
            } else if (res.data && res.data.services) {
              setServices(res.data.services)
            }
          }).catch(err => {
            alert(err?.response?.data?.message || "Something went wrong fetching numbers!");
            console.log(err)
          })
        }
      } else {
        throw new Error(response?.error);
      }
    });
  }

  useEffect(() => {
    getChannelDetails(channelId)
  }, [channelId])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData(channelData => ({
      ...channelData,
      [name]: value
    }));
  }

  const isValidData = () => {
    const { sid, token, label, type, fromdata } = channelData;
    let isValid = true;
    let message = '';
    if (sid === "" || token === "" || label === "" || type === "" || fromdata === "") {
      isValid = false;
      message = "All fields are mandatory";
    }
    return { isValid, message };
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();
    if (isValid) {
      setAdding(true);
      axios.put('/channel/' + channelId, { data: channelData })
        .then(function (response) {
          setAdding(false);
          getChannelDetails(channelId)
          alert("Channel updated successfully");
        })
        .catch(function (error) {
          setAdding(false);
          alert(error?.response?.data?.message || "Unable to process this request");
        });
    } else {
      alert(message);
    }
  }

  return (
    <div > 
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/Channels">
          Channels
        </Link>
        <Link to={'/channel-edit/'+channelId}>
          Edit Channel
        </Link>
        <Typography color="text.primary">Edit Channel Details</Typography>
      </Breadcrumbs>
      <form className="newUserForm">
        <h2>Channel Information</h2>
        <div className="newUserItem">
          <label>Label <span className="mandatory">*</span></label>
          <input value={channelData.label} onChange={handleChange} name="label" type="text" placeholder='Label' />
        </div>
        {channelData.type === "twilio-messaging-service" &&
          <div className="newUserItem">
            <label>From data <span className="mandatory">*</span></label>
            <select
              name="fromdata"
              className="popupDropdown"
              onChange={e => {
                let data = e.target.value.split(" - ")
                let fromdata = data.length ? data[1] : ""
                let label = data.length ? data[0] : ""
                setData({
                  ...channelData,
                  fromdata,
                  label
                })
              }}
              value={channelData.fromdata ? channelData.label + " - " + channelData.fromdata : ""}

            >
              <option disabled selected value="">Select messaging service</option>
              {messagingServices.map(v => ({
                label: v.friendlyName + " ( " + v.sid + " )",
                value: v.friendlyName + " - " + v.sid
              }))
                .map((channel) => {
                  return (
                    <option key={channel.value} value={channel.value}>
                      {channel.label}
                    </option>
                  );
                })}
            </select>
          </div>
        }
        {["twilio-sms", "whatsapp"].includes(channelData.type) &&
          <div className="newUserItem">
            <label>From data <span className="mandatory">*</span></label>
            <select
              name="fromdata"
              className="popupDropdown"
              onChange={e => {
                let data = e.target.value.split(" - ")
                let fromdata = data.length ? data[1] : ""
                let label = data.length ? data[0] : ""
                setData({
                  ...channelData,
                  fromdata,
                  label
                })
              }}
              value={channelData.fromdata ? channelData.label + " - " + channelData.fromdata : ""}

            >
              <option disabled selected value="">Select Phone number</option>
              {phoneNumbers.map(v => ({
                label: v.friendlyName + " ( " + v.phoneNumber + " )",
                value: v.friendlyName + " - " + v.phoneNumber
              }))
                .map((channel) => {
                  return (
                    <option key={channel.value} value={channel.value}>
                      {channel.label}
                    </option>
                  );
                })}
            </select>
          </div>
        }
                      
        <div className="newUserItem">
          <label>Channel Type <span className="mandatory">*</span></label>
          <select
            name="channel"
            className="popupDropdown"
            value={channelData.type}
            disabled={true}
          >
            <option disabled selected value="">Select channel type</option>
            {[{
              label: "SMS with Twilio Messaging Service",
              value: "twilio-messaging-service"
            }, {
              label: "SMS (with Twilio)",
              value: "twilio-sms"
            }, {
              label: "Sendgrid Mail service",
              value: "sendgrid-mail-service"
            }]
              .map((channel) => {
                return (
                  <option key={channel.value} value={channel.value}>
                    {channel.label}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="newUserItem">
          <button disabled={adding} onClick={handleSubmit} className="newChannelButton align-self-end">Update</button>
        </div>
      </form>
    </div>
  )
}
