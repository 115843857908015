import React from 'react'
import './feedback.css'

export default function Feedback() {
  return (
    <div>
      
    </div>
  )
}
