import { useState, useRef, useEffect } from "react";
import csv from 'jquery-csv';
import axios from '../../../helpers/request';
import './email.css'
import { SendOutlined, LayersOutlined } from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from "react-select"
import CustomPopup from '../../Popup/CustomPopup';

import { useAppState } from '../../../state';
import templatesIcon from "../../images/templates.svg";

/*import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';*/
import CustomImageUploadAdapter from "../../common/ckeditorImageUploadAdaptor";
import Cookies from "universal-cookie";

const SEND_THROUGH_SMS_VALUE = "sms";
const SEND_THROUGH_EMAIL_VALUE = "email";

const PHONE_FIELD = "Contact Number";
const PHONE_NUMBER_REGEX = new RegExp(/^\+[\d]+$/);

const EMAIL_FIELD = "Email";
const FIRST_NAME_FIELD = "First Name";
const LAST_NAME_FIELD = "Last Name";
const EMAIL_REGEX = new RegExp(/^\S+@\S+\.\S+$/);

const greetings = [
  { label: 'Hi @FirstName', value: 'greetHiFirst' },
  { label: 'Hi @FirstName @LastName', value: 'greetHiFirstLast' },
  { label: 'Hello @FirstName', value: 'greetHelloFirst' },
  { label: 'Hello @FirstName @LastName', value: 'greetHelloFirstLast' }
];

const GREET_DEFAULT_VALUE = greetings[0].value;

export default function EmailNow() {

  const [campaign, setCampaign] = useState({
    campaignName: '',
    contactNumber: '',
    messageBody: '',
    file: [],
    isBulk: false,
    sendThrough: SEND_THROUGH_EMAIL_VALUE,
    greetingText: GREET_DEFAULT_VALUE,
    email: ''
  });
  const fileInputRef = useRef()

  const [channel, setChannel] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const [buttonPopup, setButtonPopup] = useState(false);
  const [sending, setSending] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState('');
  const { user: loggedUser } = useAppState();

  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");
  const getChannelList = (companyId) => {
    axios.get(`/all-channels/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log(response.data)
        setChannelList(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }

  const getContactList = (companyId) => {
    axios.get(`/all-contacts/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log(response.data)
        setContactList(response.data.filter(v => v.type == "email"))
      } else {
        throw new Error(response?.error);
      }
    });
  }

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          getChannelList(cmpny)
          getContactList(cmpny)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, [])

  const openPopup = (event) => {
    event.preventDefault();
    setData([]);
    setTemplate('')
    getTemplates();
    setButtonPopup(true);
  }

  const getTemplates = () => {
    const { sendThrough } = campaign;
    let queryParams = {
      "companyId": loggedUser.added_by ? loggedUser.added_by : loggedUser.id
    }
    axios.get('/templates', { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        const templates = response.data;
        const emailTemplates = templates.filter((template) => template.type === SEND_THROUGH_EMAIL_VALUE);
        const smsTemplates = templates.filter((template) => template.type !== SEND_THROUGH_EMAIL_VALUE);

        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          setData(smsTemplates);
        } else {
          setData(emailTemplates);
        }
      } else {
        throw new Error(response?.error);
      }
    });
  }

  const resetSendThroughDetails = () => {
    setCampaign(prevCampaign => ({
      ...prevCampaign,
      contactNumber: '',
      messageBody: '',
      email: '',
      file: []
    }));
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'sendThrough') {
      resetSendThroughDetails()
    }

    setCampaign(prevCampaign => ({
      ...prevCampaign,
      [name]: value
    }));
  }

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    setSelectedContact(null)
    setSelectedContactList([])
    setCampaign(prevCampaign => ({
      ...prevCampaign,
      [name]: checked
    }));
  }

  const handleFileChange = (event) => {
    event.preventDefault();
    const { sendThrough } = campaign;

    const reader = new FileReader();
    const file = event.target.files[0];
    if (file && ['csv'].indexOf(file.name.split('.').pop().toLowerCase()) > -1) {
      try {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          const keys_to_keep = [PHONE_FIELD];

          reader.onloadend = function (event) {
            const csvData = event.target.result.replace(/"/g, "");
            const fileData = csv.toObjects(csvData);

            fileData.map(row => {
              Object.keys(row).forEach((key) => {
                if (!keys_to_keep.includes(key)) {
                  delete (row[key]);
                }
              })
              return row;
            });

            fileData.map(field => {
              if (field[PHONE_FIELD] && !field[PHONE_FIELD].startsWith('+')) {
                field[PHONE_FIELD] = '+' + field[PHONE_FIELD];
              }
              return field
            })

            const filteredFileData = fileData.filter((value, index, self) =>
              value[PHONE_FIELD] && index === self.findIndex((t) => (t[PHONE_FIELD] === value[PHONE_FIELD]))
            )

            setCampaign(prevCampaign => ({
              ...prevCampaign,
              file: filteredFileData
            }));
          }
          reader.readAsText(file);
        } else {
          const keys_to_keep = [EMAIL_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD];

          reader.onloadend = function (event) {
            const csvData = event.target.result.replace(/"/g, "");
            const fileData = csv.toObjects(csvData);

            fileData.map(row => {
              Object.keys(row).forEach((key) => {
                if (!keys_to_keep.includes(key)) {
                  delete (row[key]);
                }
              })
              return row;
            });

            const filteredFileData = fileData.filter((value, index, self) =>
              value[EMAIL_FIELD] && index === self.findIndex((t) => (t[EMAIL_FIELD] === value[EMAIL_FIELD]))
            )

            setCampaign(prevCampaign => ({
              ...prevCampaign,
              file: filteredFileData
            }));
          }
          reader.readAsText(file);
        }

      } catch (e) {
        console.log("ERROR-FILE--", e);
      }
    } else {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        file: []
      }));
      fileInputRef.current.value = "";
      alert('Only CSV file allowed');
    }
  }

  const applyTemplate = () => {
    const selectedTemplate = data.filter(temp => temp.id == template)

    if (selectedTemplate && selectedTemplate[0]) {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        messageBody: selectedTemplate[0].templatebody
      }));
    }

    setButtonPopup(false);
  }

  const resetForm = () => {
    setCampaign({
      campaignName: '',
      contactNumber: '',
      messageBody: '',
      file: [],
      isBulk: false,
      sendThrough: SEND_THROUGH_EMAIL_VALUE,
      greetingText: GREET_DEFAULT_VALUE,
      email: ''
    })
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const isValidData = () => {
    const { campaignName, contactNumber, isBulk, file, messageBody, sendThrough, email } = campaign;
    let isValid = true;
    let message = '';

    if (campaignName === "" || messageBody === "") {
      isValid = false;
      message = "All fields are mandatory";
    } else {
      if (isBulk === true) {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          if (file.length === 0) {
            isValid = false;
            message = "Please select file";
          } else {
            let invalidData = file.filter(field => {
              const inValidPhoneNumber = field[PHONE_FIELD] ? !PHONE_NUMBER_REGEX.test(field[PHONE_FIELD]) : true;
              return (inValidPhoneNumber === true)
            })

            if (invalidData.length) {
              isValid = false;
              message = "File contains invalid values";
            }
          }
        } else {
          if ((file.length === 0) && (selectedContactList.length === 0)) {
            isValid = false;
            message = "Please select file";
          } else {
            let invalidData = file.filter(field => {
              const inValidEmail = field[EMAIL_FIELD] ? !EMAIL_REGEX.test(field[EMAIL_FIELD]) : true;
              return (inValidEmail === true || field[FIRST_NAME_FIELD] == undefined || field[FIRST_NAME_FIELD] == "" || field[LAST_NAME_FIELD] == undefined || field[LAST_NAME_FIELD] == "")
            })

            if (invalidData.length) {
              isValid = false;
              message = "File contains invalid values";
            }
          }
        }
      } else {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          if (contactNumber === "") {
            isValid = false;
            message = "Please enter the contact number";
          } else if (!PHONE_NUMBER_REGEX.test(contactNumber)) {
            isValid = false;
            message = "Invalid phone number";
          }
        } else {
          if (email === "") {
            isValid = false;
            message = "Please enter the email";
          } else if (!EMAIL_REGEX.test(email)) {
            isValid = false;
            message = "Invalid email";
          }
        }
      }
    }

    return { isValid, message };
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();
    const { sendThrough } = campaign;

    if (isValid) {
      setSending(true);

      let currentChannelData = channelList.filter(v => v.id == channel)
      currentChannelData = currentChannelData.length ? currentChannelData[0] : null
      if (currentChannelData) {
        let { fromdata, label, token } = currentChannelData
        axios.post('/send-email-channel', { data: { ...campaign, file: campaign.file.concat(selectedContactList.map(v => ({ [EMAIL_FIELD]: v.value, [FIRST_NAME_FIELD]: v.label, [LAST_NAME_FIELD]: "" }))), fromdata, token, label, companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id }, actionBy: loggedUser.fullName })
          .then(function (response) {
            setSending(false);
            resetForm();
            alert("Sent emails successfully");
          })
          .catch(function (error) {
            setSending(false);
            alert("Unable to process this request");
          });
      }

    } else {
      alert(message);
    }
  }

  return (
    <div >
      <div className="newUserItem">
        <label>Please select a channel</label>
        <select value={channel} className='popupDropdown' onChange={(event) => {
          setChannel(event.target.value)
          resetForm()
        }}>
          <option value="" disabled>Select channel</option>
          {channelList.filter(v => ["sendgrid-mail-service"].includes(v.type)).map((chan) => <option key={chan.id} value={chan.id}>{chan.label}</option>)}
        </select>
      </div>

      {channel && <>
        <form className="sendNowForm">
          <div className="sendNowItem">
            <label>Email Campaign Name <span className="mandatory">*</span></label>
            <input value={campaign.campaignName} onChange={handleChange} name="campaignName" type="text" placeholder='Give your campaign a unique name' className="nameimage" />
          </div>
          <div className="sendNowItem">
            <label>Select contacts <span className="mandatory">*</span></label>
            {/* <input value={campaign.campaignName} onChange={handleChange} name="campaignName" type="text" placeholder='Give your campaign a unique name' className="nameimage" /> */}
            <Select
              isMulti={campaign.isBulk}
              value={campaign.isBulk ? selectedContactList : selectedContact}
              options={contactList.map(c => ({ label: c.label, value: c.number }))}
              className="basic-multi-select role_selectbox"
              classNamePrefix="select"
              onChange={e => {
                if (campaign.isBulk) {
                  setSelectedContactList(e)
                } else {
                  setSelectedContact(e)
                  setCampaign(prevCampaign => ({
                    ...prevCampaign,
                    email: e.value
                  }));
                }
              }}
            />
          </div>
          {/* <div className="sendNowItem">
          <label>Sent Messages Via </label>
          <RadioGroup className="flex-row" aria-label="quiz" name="sendThrough" value={campaign.sendThrough} onChange={handleChange}>
            <FormControlLabel value={SEND_THROUGH_SMS_VALUE} control={<Radio />} label="SMS" />
            <FormControlLabel value={SEND_THROUGH_EMAIL_VALUE} control={<Radio />} label="Email" />
          </RadioGroup>
        </div> */}

          {campaign.isBulk === true ? <div className="scheduleItemFile">
            <label>Add Contacts <span className="mandatory">*</span></label>
            <input onChange={handleFileChange} ref={fileInputRef} type="file" />
            <span className="scheduleItemFileText">
              Only .csv format allowed.
              {campaign.sendThrough === SEND_THROUGH_SMS_VALUE ?
                <a href="/SampleCSVFile.csv" download>Download sample .csv file here</a>
                :
                <a href="/SampleEmailCSVFile.csv" download>Download sample .csv file here</a>
              }
            </span>
          </div> :
            campaign.sendThrough === SEND_THROUGH_SMS_VALUE ?
              <div className="sendNowItem">
                <label>Add Contact Number <span className="mandatory">*</span></label>
                <input value={campaign.contactNumber} onChange={handleChange} name="contactNumber" type="text" placeholder='Enter a mobile number (Must be E.I64 format, example: +447123456789)' className="nameimage" />
              </div>
              :
              <div className="sendNowItem">
                <label>Add Email <span className="mandatory">*</span></label>
                <input value={campaign.email} onChange={e => {
                  setSelectedContact(null)
                  handleChange(e)
                }} name="email" type="text" placeholder='Enter an email' className="nameimage" />
              </div>
          }


          <div className="sendNowItem">
            <label>{campaign.sendThrough === SEND_THROUGH_SMS_VALUE ? 'Sent Bulk Messages' : 'Upload Contacts'}</label>
            <Switch checked={campaign.isBulk} onChange={handleChangeSwitch} name="isBulk" />
          </div>

          {(campaign.isBulk === true && campaign.sendThrough === SEND_THROUGH_EMAIL_VALUE) ? <div className="sendNowItem">
            <label>Choose Greetings <span className="mandatory">*</span></label>
            <select value={campaign.greetingText} onChange={handleChange} name="greetingText" className='popupDropdown'>
              <option value="" disabled>Select Greeting</option>
              {greetings.map((greet) => <option key={greet.value} value={greet.value}>{greet.label}</option>)}
            </select>
          </div> : ""}

          <div className="sendNowItem">
            <div className="templateButtonContainer">
              <label>Enter Message Body Text <span className="mandatory">*</span></label>

            </div>
            {campaign.sendThrough === SEND_THROUGH_SMS_VALUE ?
              <textarea value={campaign.messageBody} onChange={handleChange} name="messageBody" placeholder='Enter the body of your message here'></textarea>
              :
              {/*<CKEditor
                editor={DecoupledEditor}
                config={{
                  extraPlugins: [ 
                    CustomImageUploadAdapter 
                  ],
                }}
                onReady={editor => {
                  console.log('Editor is ready to use!', editor);
                  editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );

                  window.ckeditor = editor;
                }}
                onError={(error, { willEditorRestart }) => {
                  console.log('Editor error!', error);
                  if (willEditorRestart) {
                    window.ckeditor.ui.view.toolbar.element.remove();
                  }
                }}
                data={campaign.messageBody}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange({ target: { name: 'messageBody', value: data } })
                }}
              />*/}
            }
            <div className="templateButtonContainer">

              <button className="templateElementButton" onClick={openPopup} style={{ marginTop: "30px" }}>
                <img src={templatesIcon} className="filter-red" />
                <span className="templateButtonText">Select Template</span></button>
              <button disabled={sending} className="sendNowButton" onClick={handleSubmit}>Send Now</button>
            </div>
          </div>
        </form>

        <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <h3 className='popupTitle'>Please select your message template</h3>
          <select value={template} className='popupDropdown' onChange={(event) => setTemplate(event.target.value)} >
            <option value="" disabled>Select Template</option>
            {data.map((template) => <option key={template.id} value={template.id}>{template.templatename}</option>)}
          </select>
          <button className="sendNowButton" disabled={template === ""} onClick={applyTemplate}>Apply</button>
        </CustomPopup>
      </>}
    </div>
  )
}