import './user.css'
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ScheduleOutlined, KeyboardReturnOutlined } from '@material-ui/icons';
import axios from '../../../helpers/request';

import { useAppState } from '../../../state';
import { Breadcrumbs, Typography } from '@material-ui/core';

const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);

export default function NewUser() {
  const { user: loggedUser } = useAppState();
  const [user, setData] = useState({
    fullName: '',
    email: '',
    role: '',
    companyName: '',
    companyaddress: '',
    companytelephonenumber: '',
    // type0fbusiness: '',
    // nameofownerlandlord: '',
    // landlordtelephonenumber: '',
    // firesafetymanager: '',
    // deputyfiresafetymanager: '',
    // assemblypoints: '',
    chosenServices: '',
    password: "",
    cpassword: "",
    changePassword: false
  });

  const params = useParams();
  const location = useLocation();
  const isProfile = location.pathname == "/profile"
  const userId = isProfile ? loggedUser.id : params.userId
  const isEditable = isProfile || location.pathname.split("/").includes('user-edit');

  const roles = [
    { label: 'Admin', value: 'admin' },
    { label: 'Super Admin', value: 'superadmin' },
    { label: 'Ambassador', value: 'ambassador' },
  ];

  const filteredRoles = roles.filter((role) => {
    if (loggedUser.role === "superadmin" || role.value !== "superadmin") {
      return role
    }
  })

  const [adding, setAdding] = useState(false);


  const getUserDetails = (userId) => {
    axios.get('/user/' + userId).then(async (response) => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }


  useEffect(() => {
    getUserDetails(userId)
  }, [userId])



  const handleChange = (event) => {
    const { name, value } = event.target;

    setData(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  }

  const isValidData = () => {
    const {
      fullName,
      email,
      role,
      companyName,
      companyaddress,
      companytelephonenumber,
      changePassword,
      password,
      cpassword
    } = user;

    let isValid = true;
    let message = '';
    console.log('user', user)
    console.log('user', (changePassword ? (password && cpassword) : true))
    console.log('user', (changePassword ? (password === cpassword) : false))
    if (!fullName || !email || !role || !companyName || !companyaddress || !companytelephonenumber || !(changePassword ? (password && cpassword) : true)) {
      isValid = false;
      message = "All fields are mandatory";
    } else if (!EMAIL_REGEX.test(email)) {
      isValid = false;
      message = "Please enter valid email address";
    }else if(changePassword?password!==cpassword:false){
      isValid = false
      message = "Confirm password doesnt match"
    }

    return { isValid, message };
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();

    if (isValid) {
      setAdding(true);
      if (!isProfile) {
        axios.put('/user/' + userId, { data: user })
          .then(function (response) {
            setAdding(false);
            getUserDetails(userId)
            alert("User updated successfully");
          })
          .catch(function (error) {
            setAdding(false);
            alert(error?.response?.data?.message || "Unable to process this request");
          });
      } else {
        axios.put('/user-profile/' + userId, { data: user })
          .then(function (response) {
            setAdding(false);
            getUserDetails(userId)
            alert("User updated successfully");
          })
          .catch(function (error) {
            setAdding(false);
            alert(error?.response?.data?.message || "Unable to process this request");
          });
      }
    } else {
      alert(message);
    }
  }

  return (
    <div >
      {!isProfile && <Breadcrumbs aria-label="breadcrumb">
        <Link to="/users">
          Users
        </Link>
        <Typography color="text.primary">User Details</Typography>
      </Breadcrumbs>}
      <form className="newUserForm">
        <h2>Contact Information</h2>
        <div className="newUserItem">
          <label>Full Name <span className="mandatory">*</span></label>
          <input value={user.fullName} onChange={handleChange} name="fullName" type="text" placeholder='Enter the users full name' />
        </div>
        <div className="newUserItem">
          <label>Email Address <span className="mandatory">*</span></label>
          <input value={user.email} onChange={handleChange} name="email" type="email" placeholder='Enter the users email address' />
        </div>
        <div className="newUserItem">
          <label>Choose Role <span className="mandatory">*</span></label>
          <select value={user.role} onChange={handleChange} name="role" className='popupDropdown' disabled>
            <option value="" disabled>Select Role</option>
            {filteredRoles.map((role) => <option key={role.value} value={role.value}>{role.label}</option>)}
          </select>
        </div>
        {isProfile && <>
          {user.changePassword && <><div className="newUserItem">
            <label>Password <span className="mandatory">*</span></label>
            <input value={user.password} onChange={handleChange} name="password" type="password" placeholder='Enter a password for the user' />
          </div>
            <div className="newUserItem">
              <label>Confirm Password <span className="mandatory">*</span></label>
              <input value={user.cpassword} onChange={handleChange} name="cpassword" type="password" placeholder='Confirm the passwaord' />
            </div></>}
          <div className="newUserItem flex-row align-items-center">
            <input checked={user.changePassword} onChange={handleChange} name="changePassword" type="checkbox" />
            <label className='mb-0 ms-2'>Change Password <span className="mandatory">*</span></label>
          </div>
        </>}

        <div class="companyInfo">
          <h2>Company Information</h2>

          {/* <div className="newUserItem">
            <label>Type of Business <span className="mandatory">*</span></label>
            <input value={user.type0fbusiness} onChange={handleChange} name="type0fbusiness" type="text" placeholder='Type of business' />
          </div> */}


          <div className="newUserItem">
            <label>Name of Premises <span className="mandatory">*</span></label>
            <input value={user.companyName} onChange={handleChange} name="companyName" type="text" placeholder='Name of Premises ' />
          </div>

          <div className="newUserItem">
            <label>Address <span className="mandatory">*</span></label>
            <input value={user.companyaddress} onChange={handleChange} name="companyaddress" type="text" placeholder='Address ' />
          </div>

          <div className="newUserItem">
            <label>Telephone Number <span className="mandatory">*</span></label>
            <input value={user.companytelephonenumber} onChange={handleChange} name="companytelephonenumber" type="text" placeholder='Telephone Number ' />
          </div>


          {/* <div className="newUserItem">
            <label>Fire Safety Manager <span className="mandatory">*</span></label>
            <input value={user.firesafetymanager} onChange={handleChange} name="firesafetymanager" type="text" placeholder='Fire Safety Manager ' />
          </div>


          <div className="newUserItem">
            <label>Deputy Fire Safety Manager <span className="mandatory">*</span></label>
            <input value={user.deputyfiresafetymanager} onChange={handleChange} name="deputyfiresafetymanager" type="text" placeholder='Deputy Fire Safety Manager ' />
          </div>



          <div className="newUserItem">
            <label>Assembly Points<span className="mandatory">*</span></label>
            <input value={user.assemblypoints} onChange={handleChange} name="assemblypoints" type="text" placeholder='Assembly Points' />
          </div>


          <div className="newUserItem">
            <label>Name of Owner/Landlord <span className="mandatory">*</span></label>
            <input value={user.nameofownerlandlord} onChange={handleChange} name="nameofownerlandlord" type="text" placeholder='Name of Owner/Landlord ' />
          </div>


          <div className="newUserItem">
            <label>Telephone Number <span className="mandatory">*</span></label>
            <input value={user.landlordtelephonenumber} onChange={handleChange} name="landlordtelephonenumber" type="text" placeholder='Telephone Number ' />
          </div> */}
        </div>
        <div className="">
          {isEditable ? <button disabled={adding} onClick={handleSubmit} className="btn btn-primary text-white br-10">Update</button> : ""}
        </div>

      </form>
    </div>
  )
}
