import './contacts.css'
import { DataGrid } from '@material-ui/data-grid';
import { AddOutlined, DeleteOutlined, EditOutlined, VisibilityOutlined } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import axios from '../../../helpers/request';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

// import Cookies from 'universal-cookie';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import {
//   DataGridPro,
//   GridColDef,
//   GridRowsProp,
//   DataGridProProps,
// } from '@mui/x-data-grid-pro';
// import Accordion from 'react-bootstrap/Accordion'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Contacts() {
  // const history = useHistory();
  // const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  // const [tabValue, setTabValue] = useState(0);
  // const cookies = new Cookies();
  // const userToken = cookies.get("cs_token");

  // const getcontacts = () => {
  //   axios
  //     .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
  //       headers: {
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       // if (res.data.length > 0) {

  //       axios.get(`/all-contact-list/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`).then(async (response) => {
  //         if (response.status === 200 && response.data) {
  //           setList(response.data)
  //           axios.get(`/all-contacts/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`).then(async (resInner) => {
  //             if (resInner.status === 200 && resInner.data) {
  //               setData(resInner.data)
  //             } else {
  //               throw new Error(resInner?.error);
  //             }
  //           });
  //         } else {
  //           throw new Error(response?.error);
  //         }
  //       });


  //       // }
  //     })
  //     .catch((err) => {
  //       console.log("errr", err);
  //     });
  // }

  const getContactList = () => {
    axios.get(`/all-contact-list`)
      .then(async (response) => {
        if (response.status === 200 && response.data) {
           console.log("c-lists::", response.data)
          setList(response.data)
        }
      })
      .catch((err) => {
        console.error("error", err);
      });
  }

  useEffect(() => {
    // getcontacts()
    getContactList()
  }, [])

  // const handleDelete = (id, label) => {
  //   const confirmDelete = window.confirm(`Are you sure to delete ${label}`);
  //   if (confirmDelete) {
  //     axios.delete(process.env.REACT_APP_API_BASEURL + '/contact/' + id)
  //       .then(function (response) {
  //         getcontacts()
  //         alert("Contact deleted successfully");
  //       })
  //       .catch(function (error) {
  //         alert(error?.response?.data?.message || "Unable to delete contact");
  //       });
  //   }
  // };

  const handleDeleteList = (listId, label) => {
    const confirmDelete = window.confirm(`Are you sure to delete ${label}`);
    if (confirmDelete) {
      axios.delete('/contact-list-delete/' + listId)
        .then(function (response) {
          // getcontacts()
          getContactList()
          alert("List deleted successfully");
        })
        .catch(function (error) {
          alert(error?.response?.data?.message || "Unable to delete contact");
        });
    }
  };

  // const columns = [
  //   { field: 'id', headerName: 'ID', width: 90 },
  //   { field: 'label', headerName: 'Name', width: 200 },
  //   { field: 'number', headerName: 'Number', width: 200 },
  //   { field: 'type', headerName: 'Contact Type', width: 200 },
  //   {
  //     field: 'action',
  //     headerName: 'Action',
  //     width: 400,
  //     renderCell: (params) => {
  //       return (
  //         <div className='userListActions'>
  //           <Link to={'/contact-edit/' + params.row.id}>
  //             <button className="actionElementButton">
  //               <EditOutlined className='actionElementIcon' />
  //               {/* <span className="actionButtonText">Edit</span> */}
  //             </button>
  //           </Link>
  //           <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.label)} />
  //         </div>
  //       )
  //     }
  //   }
  // ];

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   // setPageNumber(1)
  // }

  const contactListColumns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link className="color-gray" to={'/edit-contact-list/' + params.row.id}>
            {params.row.name}
          </Link>
        )
      }
    },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className='userListActions'>
            <Link to={'/edit-contact-list/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className='actionElementIcon' />
              </button>
            </Link>
            <DeleteOutlined
              className="userListDelete"
              onClick={() => handleDeleteList(params.row.id, params.row.name)}
            />
          </div>
        )
      }
    }
  ];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <div>
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to='/create-contact-list'>
            <button className="btn btn-primary text-white br-10">Add List</button>
          </Link>
        </div>
      </div>

      <DataGrid
        rows={list}
        columns={contactListColumns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
