import './importcontacts.css';

import React, { useState, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { Grid } from '@mui/material';

import axios from "../../../../helpers/request";
import leftIcon from "../../../images/contactlefticon.svg";
import rightIcon from "../../../images/contactrighticon.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minwidth: '400px'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const tableColumnLabels = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    number: "Phone Number",
    address: "Address",
    pin: "Pin",
    expiry: "Expiry",
    reference: "Reference",
    uid: "UID",
};

export default function MappingColumns(props) {
    let defaultColumnNeedUpdate = {"headerKeyName": "", "headerKeyIndex": ""};
    let tableDivEle = useRef();
    let tableMainDivEle = useRef();
    const [open, setOpen] = useState(false);
    const [openSaveDbModal, setOpenSaveDbModal] = useState(false);
    const [isAllColumnsMatched, setisAllColumnsMatched] = useState(false);
    const [contactColumnsData, setContactColumnsData] = useState([]);
    const [columnSelected, setColumnSelected] = useState("");
    const [modalColumns, setModalColumns] = useState([]);
    const [columnNeedUpdate, setColumnNeedUpdate] = useState(defaultColumnNeedUpdate);
    const [saveContacts, setSaveContacts] = useState("no");
    const [newListName, setNewListName] = useState("");

    let  tableRowData = props.browseFileData;
    let headers = [];
    let firstThreeRowData = [];
    let minTableLength = (props.componentCalledSource === "contactList") ? 6 : 4;

    if (tableRowData.length) {
        headers = Object.keys(tableRowData[0]);
        firstThreeRowData = [tableRowData[0], tableRowData[1], tableRowData[2]];
    }

    const checkedAllColumnsMatched = () => {
        let firstRow = tableRowData[0];
        let allColumns = modalColumns.length;
        let checkedColumns = modalColumns.filter((item) => {
                let forcedUnchecked = (firstRow.hasOwnProperty(item.name+'_forcedUnchecked')) ? firstRow[item.name+'_forcedUnchecked'] : '';
                let columnIsChecked = item.isChecked && !forcedUnchecked;
                return columnIsChecked;
            }
        );
        setisAllColumnsMatched((allColumns === checkedColumns.length) ? true : false);
    }

    //To open modal while click on table header
    const openEditcolumnModal = (headerKey, index) => {
        setColumnNeedUpdate({"headerKeyName": headerKey, "headerKeyIndex": index});
        setOpen(true);
    };

    // To update column header as per selected option
    const handleEditcolumnSubmit = () => {
        console.log("columnNeedUpdate", columnNeedUpdate);
        console.log("columnSelected", columnSelected);
        props.updateDataColumn(columnNeedUpdate, columnSelected);
        setOpen(false);
        setColumnSelected("");
        setColumnNeedUpdate(defaultColumnNeedUpdate);
    }

    const handleCancelAction = () => {
        setOpen(false);
        setColumnSelected("");
        setColumnNeedUpdate(defaultColumnNeedUpdate);
    };

    const removeSelectedColumn = (headerKey, index, event) => {
        let tempContactColumnsData = contactColumnsData;
        tempContactColumnsData[headerKey] = false;
        setContactColumnsData(tempContactColumnsData);

        let tempModalColumns = modalColumns.map(data => {
            if (data.name === headerKey) {
                data.isChecked = false;
                data.forcedUnchecked = true;
            }
            return data;
        });
        setModalColumns(tempModalColumns);
        checkedAllColumnsMatched();

        tableRowData.map((data) => {
            data[headerKey+'_forcedUnchecked'] = !event.target.checked;
        });
        props.setBrowseFileData(tableRowData);
    }

    const rowDataDisplay = row => {
        let returnHtml = "";
        for (const property in row) {
            let isUncheckedColumn = property.includes("_forcedUnchecked");
            if (!isUncheckedColumn) {
                let isforcedUnchecked = row.hasOwnProperty(property+'_forcedUnchecked') ? 
                    row[property+'_forcedUnchecked'] : 
                    '';
                let tdClassName = (contactColumnsData[property] && !isforcedUnchecked) ? 
                    'selected-column-data-style' : 
                    'unrecognized-column-data-style';
                returnHtml += `<td class=${tdClassName}> ${row[property]}`;
            }
        }
        return returnHtml;
    }

    const saveContactAction = () => {
        let matchedColumns = modalColumns.filter((col) => col.isChecked);
        (matchedColumns.length > 0) ? props.continueHandler(saveContacts, newListName) : alert("Min 1 columns needs to be matched");
    }

    useEffect(() => {
        if (props.componentCalledSource !== "contactList") { props.setUpdateModalSize(true); }
        getContactsColumns();
    }, [props.browseFileData]);

    useEffect(() => {
        checkedAllColumnsMatched();
    }, [modalColumns]);

    // Get table colms from db to display while edit table header
    const getContactsColumns = () => {
        axios.get(`/get-contact-table-columns`)
            .then((res) => {
                let allColumns = res.data;
                let saveDataColumns = [];
                allColumns.filter((row, index) => {
                    if (row.Field !== 'id' && row.Field !== 'listId' && row.Field !== 'companyId' && row.Field !== 'type' && row.Field !== 'last_name') {
                        saveDataColumns.push(row.Field);
                    }
                });
                let availableColumns = [];
                let modalColumnList = [];
                let haveAllColumns = modalColumns.length === saveDataColumns.length;

                allColumns = allColumns.filter((row, index) => {
                    if (row.Field !== 'id' && row.Field !== 'listId' && row.Field !== 'companyId' && row.Field !== 'type' && row.Field !== 'last_name') {
                        let colname = row.Field;
                        let isChecked = headers.includes(colname);
                        let forcedUnchecked = false;
                        let columnState = modalColumns.filter((item) => {
                            return (item.name === row.Field) ? item : null;
                        });
                        columnState = (columnState.length) ? columnState[0]: [];

                        if (columnState.length) {
                            isChecked = isChecked && !columnState.forcedUnchecked;
                            forcedUnchecked = columnState.forcedUnchecked;
                        }

                        availableColumns[colname] = isChecked;
                        if (!columnState.length) {
                            modalColumnList.push({
                                "index": index,
                                "name": row.Field,
                                "isChecked": isChecked,
                                "forcedUnchecked": forcedUnchecked,
                            });
                        }
                    }
                });

                if (modalColumnList.length) {
                    setModalColumns(modalColumnList)
                }
                setContactColumnsData(availableColumns);
                checkedAllColumnsMatched();
            })
            .catch((err) => {
                console.error("errr", err);
            });
    }

    const handleSubmit = () => {
        let matchedColumns = modalColumns.filter((col) => col.isChecked );
        let matchedColumnNames = modalColumns.map((col) => (col.isChecked) ? col.name: null );

        /*if (props.componentCalledSource === "contactList") {
            props.continueHandler();
        } else {
            //temp setting default save contact list
            setOpenSaveDbModal(true);
            //props.continueHandler('yes', "list-"+ Math.floor(Math.random() * 100));
        }*/

        if (props.componentCalledSource === "contactList") {
            let minColumnsMatched = matchedColumnNames.includes("first_name") &&
                matchedColumnNames.includes("last_name") &&
                (matchedColumnNames.includes("number") || matchedColumnNames.includes("email"));
            (minColumnsMatched) ? props.continueHandler() : alert("Min first_name, last_name and email/number columns needs to be matched");
        } else {
            /*let isCommonColumnsMatched = matchedColumnNames.includes("first_name") &&
                //matchedColumnNames.includes("last_name") &&
                matchedColumnNames.includes("address") &&
                matchedColumnNames.includes("pin") &&
                matchedColumnNames.includes("expiry") &&
                matchedColumnNames.includes("reference") &&
                matchedColumnNames.includes("uid");
            let finalMatchFlag = false;
            let finalMsg = "";

            if (props.componentCalledSource === "sms_emailChannel") {
                finalMatchFlag = isCommonColumnsMatched && 
                    matchedColumnNames.includes("number") &&
                    matchedColumnNames.includes("email");
                finalMsg = "All columns needs to be matched";
            } else if (props.componentCalledSource === "smsChannel") {
                finalMatchFlag = isCommonColumnsMatched && 
                    matchedColumnNames.includes("number");
                finalMsg = "All columns needs to be matched except email";
            } else if (props.componentCalledSource === "emailChannel") {
                finalMatchFlag = isCommonColumnsMatched &&
                    matchedColumnNames.includes("email");
                finalMsg = "All columns needs to be matched except number";
            }
            (finalMatchFlag) ?
                setOpenSaveDbModal(true) :
                alert(finalMsg);*/
            setOpenSaveDbModal(true);
        }
    }

    return (
        <>
            <span className='import-contact-header'>
                Match column labels to contact information
            </span>

            <Grid container className="mt-20 mappingColum-grid">
                <Grid item xs={12} md={12} className="fileDataTable">
                    <span className='import-contact-p'>{tableRowData.length} contacts were recognized in this file</span>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className="fileDataTable match-table-container mt-20" ref={tableMainDivEle}>
                    <table  className="mappingColumn-match-table" aria-label="custom pagination table match-table" border="1" ref={tableDivEle}>
                        <thead>
                            <tr>
                                {headers.map((headerKey, index) => {
                                    let isUncheckedColumn = headerKey.includes("_forcedUnchecked");
                                    if (!isUncheckedColumn) {
                                        let isChecked = contactColumnsData[headerKey];
                                        let firstRow = tableRowData[0];
                                        let forcedUnchecked = (firstRow.hasOwnProperty(headerKey+'_forcedUnchecked')) ? firstRow[headerKey+'_forcedUnchecked'] : '';
                                        let columnIsChecked = isChecked && !forcedUnchecked;
                                        return (
                                            <th className={ columnIsChecked ? 'selected-column-header-style' : 'unrecognized-column' }>
                                                <div className="column-header-box">
                                                    <div className="column-header-name-box" onClick={() => openEditcolumnModal(headerKey, index)}>
                                                        { columnIsChecked && 
                                                            <>
                                                                <BorderColorIcon sx={{color: '#005CC8'}} /> &nbsp;&nbsp;
                                                            </>
                                                        }
                                                        { tableColumnLabels[headerKey] ? tableColumnLabels[headerKey] : headerKey }
                                                    </div>
                                                    { (columnIsChecked) ? (
                                                            <div className="column-header-name-checkbox">
                                                                <Tooltip title="Column is matched, click here to remove matched" placement="top-start">
                                                                    <input className="col-cbox" type='checkbox'
                                                                        defaultChecked={columnIsChecked}
                                                                        onClick={(evt) => (columnIsChecked) ?
                                                                            removeSelectedColumn(headerKey, index, evt) :
                                                                            openEditcolumnModal(headerKey, index)}
                                                                    />
                                                                </Tooltip>
                                                            </div>
                                                        ) : (
                                                            <div className="column-header-name-checkbox" onClick={(evt) => 
                                                                openEditcolumnModal(headerKey, index)}>
                                                                <Tooltip title="Column don't matched, click to match column" placement="top-start">
                                                                    <ErrorOutlineIcon sx={{color:'#fff'}}/>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {firstThreeRowData.map((item, index) => 
                                <tr className="content" 
                                    dangerouslySetInnerHTML={{__html: rowDataDisplay(item)}}>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </Grid>
                
                {headers.filter((item) => !item.includes("_forcedUnchecked")).length > minTableLength &&
                    <Grid item xs={12} md={12} className="fileDataTable scroll-button-div">
                        <div className='scroll-button-box no-scroll-table'>
                            <img src={leftIcon} title="left-icon" className="left-s-icon" onClick={ () =>tableMainDivEle.current.scrollLeft -= 600 } />
                        </div>
                        <div className='scroll-button-box have-scroll-table ml-20'>
                            <img src={rightIcon} title="right-icon" className="right-s-icon" onClick={ () =>tableMainDivEle.current.scrollLeft += 600 }/>
                        </div>
                    </Grid>
                }

                <Grid item xs={12} md={12} className="fileDataTable mt-20">
                    <span  className='import-contact-p'>
                        {modalColumns.filter((item) => {
                            let firstRow = tableRowData[0];
                            let forcedUnchecked = (firstRow.hasOwnProperty(item.name+'_forcedUnchecked')) ? firstRow[item.name+'_forcedUnchecked'] : '';
                            if (item.isChecked && !forcedUnchecked) {
                                return item;
                            }
                        }).length} 
                        &nbsp;matched columns will be import in contacts out of&nbsp;
                        {headers.filter((item) => !item.includes("_forcedUnchecked")).length}&nbsp;columns in file
                    </span>
                </Grid>

                <Grid item xs={12} md={12}>
                    <button
                    onClick={handleSubmit}
                    className="btn btn-primary text-white br-10 importsubmit"
                    >Complete Import</button>

                    <button
                    onClick={() => props.handleBackClick()}
                    className="btn btn-white-red br-10 importsubmit ml-20"
                    >Back</button>
                </Grid>
            </Grid>

            {/* Modals for process */}
                { !isAllColumnsMatched &&
                    <BootstrapDialog
                        onClose={handleCancelAction}
                        aria-labelledby="customized-dialog-title-1"
                        open={open}
                        className="editcolumnModal"
                    >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialogTitle-component">
                        Edit Column Label
                        <IconButton
                            aria-label="close"
                            onClick={handleCancelAction}
                            sx={{
                                float: 'right',
                                padding: '0px',
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                        <CloseIcon />
                    </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <label>
                            Select a field for this column
                        </label>
                        <select
                            name="contact"
                            className="popupDropdown"
                            defaultValue={columnSelected}
                            onChange={(e) => setColumnSelected(e.target.value)}
                        >
                            <option disabled selected value="">Select contact type</option>
                            {
                                modalColumns.map((col) => {
                                    let isChecked = col.isChecked;
                                    let firstRow = tableRowData[0];
                                    let forcedUnchecked = (firstRow.hasOwnProperty(col.name+'_forcedUnchecked')) ? firstRow[col.name+'_forcedUnchecked'] : '';
                                    let columnIsChecked = isChecked && !forcedUnchecked;
                                    return (
                                        <option key={col.index} value={col.name} disabled={columnIsChecked}>
                                            {tableColumnLabels[col.name] ? tableColumnLabels[col.name] : col.name} {columnIsChecked ? '- matched': ''}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={handleEditcolumnSubmit}
                        className="btn btn-primary text-white br-10"
                        >Save changes</button>
                        <button
                        onClick={handleCancelAction}
                        className="btn btn-white-red br-10 ml-20"
                        >Cancel</button>
                    </DialogActions>
                    </BootstrapDialog>
                }

                { isAllColumnsMatched &&
                    <BootstrapDialog
                        onClose={handleCancelAction}
                        aria-labelledby="customized-dialog-title-1"
                        open={open}
                        className="editcolumnModal"
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="editcolumnModal22">
                            All Columns Matched
                        </DialogTitle>
                        <DialogContent dividers>
                            <label>
                                All columns has been matched, others extra data will be ignored.
                                If you think have make mistake then uncheck column and match with other column.
                            </label>
                        </DialogContent>
                        <DialogActions>
                            <button
                            onClick={handleCancelAction}
                            className="btn btn-primary text-white br-10"
                            >Okay</button>
                        </DialogActions>
                    </BootstrapDialog>
                }

                { (props.componentCalledSource !== "contactList") &&
                    <BootstrapDialog
                        onClose={() => setOpenSaveDbModal(false)}
                        aria-labelledby="customized-dialog-title-1"
                        open={openSaveDbModal}
                        className="editcolumnModal"
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="editcolumnModal22">
                            Save Contacts
                        </DialogTitle>
                        <DialogContent dividers>
                            <div className="">
                                <h6>Do you like to save all this contacts?</h6>
                                <input type="radio" name="saveContacts" 
                                    checked={saveContacts === 'yes' ? 'checked' : ''} defaultValue="yes" 
                                    onClick={() => setSaveContacts('yes')} />
                                <span className='ml-5'>
                                    Yes
                                </span>
                                <input type="radio" className="ml-10"
                                    name="saveContacts" checked={saveContacts === 'no' ? 'checked' : ''} 
                                    defaultValue="no" onClick={() => setSaveContacts('no')} 
                                    /><span className='ml-5'>No</span>
                            </div>
                            { saveContacts === 'yes' && 
                                <div className="sendNowItem">
                                    <label>List name</label>
                                    <input type="text" name="newListName" placeholder="Enter name for this new contact list" onChange={(e) => setNewListName(e.target.value)}/>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <button
                            onClick={() => saveContactAction()}
                            className="btn btn-primary text-white br-10"
                            >Submit</button>
                            <button
                            onClick={() => setOpenSaveDbModal(false)}
                            className="btn btn-white-red br-10 ml-20"
                            >Cancel</button>
                        </DialogActions>
                    </BootstrapDialog>
                }
            {/* Modals for process */}
        </>
    )
}