import "../../NewContact/newcontact.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../../../helpers/request";
import PropTypes from 'prop-types';

import { Breadcrumbs, Typography } from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DataGrid } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import { Grid } from '@mui/material';

import { useAppState } from "../../../../state";

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


const contactType = [
    {
      label: "Phone Number",
      value: "phone"
    },
    {
      label: "SMS",
      value: "sms"
    },
    {
      label: "Email",
      value: "email"
    },
    {
      label: "WhatsApp",
      value: "whatsapp"
    },
    {
      label: "SMS & Email",
      value: "sms_email"
    },
];

const filter = createFilterOptions();

export default function CreateEdit(props) {
    const [data, setData] = useState({
            name: "",
            type: "",
            parent_type: "",
        });
    const [parentList, setParentList] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user: loggedUser } = useAppState();
    const companyId = (loggedUser.added_by ? loggedUser.added_by : loggedUser.id);

    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const isEditable = location.pathname.split("/").includes('edit-contact-list');
    
    useEffect(() => {
        if (isEditable && params.listId) {
            getListDetails();
        }
        getParentTypes();
    }, [])

    const getParentTypes = () => {
        let url = `/get-contactlist-parents/all`;
        if (loggedUser.role !== "superadmin") {
            url = '/get-contactlist-parents/' + companyId;
        }
        axios.get(url)
            .then((res) => {
                if (res.data) {
                    console.log(res.data);
                    setParentList(res.data);
                }
            })
            .catch((err) => {
                console.error("errr", err);
            });
    }

    const getListDetails = () => {
        axios.get(`/contact-list/${params.listId}`)
            .then((res) => {
                if (res.data && res.data.listId) {
                    setData(res.data);
                }
            })
            .catch((err) => {
                console.error("errr", err);
            });
    }

    const isValidData = () => {
        const { name, type } = data;
        let isValid = true;
        let message = '';

        if (name == "") {
            isValid = false;
            message = "Please enter the list name";
        }

        if (type == "") {
            isValid = false;
            message = "Please select type of contact list";
        }

        return { isValid, message };
    }

    const handleSubmit = () => {
        setLoading(true)
        const { isValid, message } = isValidData();
        if (isValid) {
            let apiParams = JSON.parse(JSON.stringify(data));
            if (apiParams.parent_type !== "" && apiParams.parent_type !== null) {
                apiParams.parent_type = apiParams.parent_type.inputValue;
            }
            if (!isEditable) {
                axios.post("add-contact-list", {
                    ...apiParams
                }).then(res => {
                    if (res.data && res.data.affectedRows === 1) {
                        alert("List added successfully");
                        history.push(`/view-contact-list/${res.data.insertId}`);
                    } else {
                        alert("Something went wrong adding list!");
                    }
                    setLoading(false)
                    // console.log(res.data)
                }).catch(err => {
                    alert(err?.response?.data?.message || "Something went wrong adding list!");
                    setLoading(false)
                    console.error(err)
                })
            } else {
                axios.put(`contact-list/${params.listId}`, {
                    ...apiParams
                }).then(res => {
                    if (res.data) {
                        // history.push("/contacts")
                        console.log("res.data", res.data);
                        alert("List updated successfully");
                        props.setCurrentPage("view-list");
                    } else {
                        alert("Something went wrong updating list!");
                    }
                    setLoading(false)
                    // console.log(res.data)
                }).catch(err => {
                    alert(err?.response?.data?.message || "Something went wrong updating list!");
                    setLoading(false)
                    console.error(err)
                })
            }
        } else {
            setLoading(false)
            alert(message);
        }
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className="mb-5">
                <Link to="/contacts"> Contact Lists </Link>
                <Typography color="text.primary">{isEditable ? 'Edit List' : 'New List'}</Typography>
            </Breadcrumbs>

            <Grid container className="contactListForm">
                <Grid item xs={12} md={4} lg={4}>
                    <label>
                        Type <span className="mandatory">*</span>
                    </label>
                    <select
                        name="type"
                        className="popupDropdown"
                        onChange={e => {
                            setData({
                                ...data,
                                type: e.target.value,
                            })
                        }}
                        value={data.type}
                    >
                        <option disabled selected value="">Select contact list type</option>
                        {contactType.map((contact) => {
                            return (
                            <option key={contact.value} value={contact.value}>
                                {contact.label}
                            </option>
                            );
                        })}
                    </select>
                </Grid>

                <Grid item xs={12} md={8} lg={8} ></Grid>
                <Grid item xs={12} md={4} lg={4} className="mt-15">
                    <Grid item xs={12} md={12} lg={12} className="sendNowlItem">
                        <label>
                            Parent Type
                        </label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className="sendNowItem">
                        <Autocomplete
                            value={data.parent_type}
                            onChange={(event, newValue) => {
                                setData({ ...data, parent_type: newValue })
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.parent_type);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        parent_type: `Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={parentList}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                return option.inputValue;
                                }
                                // Regular option
                                return option.parent_type;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.parent_type}</li>}
                            sx={{ width: '100%' }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8} lg={8} ></Grid>
                <Grid item xs={12} md={4} lg={4} className="mt-15">
                    <Grid item xs={12} md={12} lg={12} className="sendNowlItem">
                        <label>
                            List name <span className="mandatory">*</span>
                        </label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className="sendNowItem">
                        <input
                            name="label"
                            type="text"
                            onChange={e => setData({ ...data, name: e.target.value })}
                            value={data.name}
                            placeholder="Enter the name"
                        />
                    </Grid>
                </Grid>
                    
                <Grid item xs={12} md={8} lg={8} ></Grid>
                <Grid item xs={12} md={4} lg={4} className="mt-15">
                    <button
                        disabled={(Object.keys(data).filter(v => !v).length > 0) || loading}
                        onClick={handleSubmit}
                        className="btn btn-primary text-white br-10 importsubmit"
                    >
                        {!isEditable ? "Create" : "Save"}
                    </button>
                </Grid>
            </Grid>
        </>
    )
}