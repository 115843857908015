import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SecurityOutlined } from '@material-ui/icons';
import axios from "axios";
import PasswordChecklist from "react-password-checklist"

import './passwordreset.css'

export default function PasswordCreate() {
    // const location = useLocation();
    const history = useHistory();

    const location = useLocation();
    const { search } = location
    const searchParams = new URLSearchParams(search);
    const token = (searchParams && searchParams.get('token')) ? searchParams.get('token').replace(/\s/g, '+') : "";

    if(!token) {
        alert("Invalid url");
        history.push("/");
    }

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updating, setUpdating] = useState(false);

    const isValidData = () => {
        let isValid = true;
        let message = '';

        if (newPassword === "" || confirmPassword === "") {
            isValid = false;
            message = "All fields are mandatory";
        } else if (newPassword !== confirmPassword) {
            isValid = false;
            message = "Password and Confirm Password are not matched";
        }

        return { isValid, message };
    }

    const resetPassword = (event) => {
        event.preventDefault();
        const { isValid, message } = isValidData();
        if (isValid) {
            setUpdating(true);
            axios.post(process.env.REACT_APP_API_BASEURL + '/create-password', { token: token, password: confirmPassword })
                .then(function (response) {
                    setUpdating(false);
                    alert("Password created successfully");
                    history.replace('/');
                })
                .catch(function (error) {
                    setUpdating(false);
                    alert("Unable to create password");
                });
        } else {
            alert(message);
        }

    }

    return (
        <div className='passwordReset'>

            <form className="passwordResetForm">
                <div className="passwordResetItem">
                    <label>Password</label>
                    <input onChange={(e) => { setNewPassword(e.target.value); }} type="password" placeholder='Enter a password for the user' />
                </div>
                <div className="passwordResetItem">
                    <label>Confirm Password</label>
                    <input onChange={(e) => { setConfirmPassword(e.target.value); }} type="password" placeholder='Confirm the password' />

                    <div className="passwordContainer">
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                            minLength={8}
                            value={newPassword}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => { }} />
                    </div>

                    <button disabled={updating} onClick={resetPassword} className="passwordResetButton">Create</button>
                </div>

            </form>
        </div>
    )
}
