import './importcontacts.css';
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Grid } from '@mui/material';
import { Breadcrumbs, Typography } from "@material-ui/core";

import BrowseFile from './BrowseFile';
import SelectMethod from './SelectMethod';
import MappingColumns from './MappingColumns';
import CopyPasteFile from './CopyPasteFile';
import SelectContactList from './selectContactList';

import { useAppState } from "../../../../state";
import axios from "../../../../helpers/request";

export default function Importcontacts(props) {
    const history = useHistory();
    const componentCalledSource = props.hasOwnProperty('componentCalledSource') ? props.componentCalledSource : "contactList";
    const { user: loggedUser } = useAppState();
    const currentListID = (componentCalledSource === "contactList") ? history.location.state.listId : "makeNewList";
    const currentListType = (componentCalledSource === "contactList") ? history.location.state.type : "makeNewList";
    const [currentStep, setCurrentStep] = useState("S1_selectMethod");
    const [lastStep, setLastStep] = useState("S1_selectMethod");
    const [browseFileData, setBrowseFileData] = useState([]);
    const [importOptionSelected, setImportOptionSelected] = useState("");

    let stepView = <SelectMethod />;

    const continueHandler = (saveInDatabase = "yes", newListName ="") => {
        setLastStep(currentStep);
        switch (currentStep) {
            case "S1_selectMethod" :
                 if (importOptionSelected === "upload_file") {
                    setCurrentStep("S2_browseFile");
                 } else if (importOptionSelected === "copy_paste_file") {
                    setCurrentStep("S2_copyPasteFile")
                 } else if (importOptionSelected === "choose_existing_contact_list") {
                    setCurrentStep("S2_selectContactList")
                 }
                break;
            case "S2_browseFile" :
                setCurrentStep("S3_mappingColumns");
                break;
            case "S2_copyPasteFile" :
                setCurrentStep("S3_mappingColumns");
                break;
            case "S2_selectContactList" :
                setCurrentStep("S2_selectContactList");
                break;
            case "S3_mappingColumns" :
                saveAllContacts(saveInDatabase, newListName);
                setCurrentStep("S3_mappingColumns");
                break;
        }
    }

    const handleBackClick = () => {
        setCurrentStep(lastStep);
        setLastStep("S1_selectMethod");
    }

    const getSingleRowData = (singleData, newListName) => {
        let newRow = {};
        newRow.listId = currentListID;
        newRow.companyId = loggedUser.id;
        newRow.newListName = newListName;

        if (singleData.hasOwnProperty('first_name')) {
            if (!singleData.hasOwnProperty('first_name_forcedUnchecked')) {
                newRow.first_name = singleData.first_name;
            } else if (!singleData['first_name_forcedUnchecked']) {
                newRow.first_name = singleData.first_name;
            }
        }

        if ( singleData.hasOwnProperty('last_name')) {
            if (!singleData.hasOwnProperty('last_name_forcedUnchecked')) {
                newRow.last_name = singleData.last_name;
            } else if (!singleData['last_name_forcedUnchecked']) {
                newRow.last_name = singleData.last_name;
            }
        }

        if ( singleData.hasOwnProperty('email')) {
            if (!singleData.hasOwnProperty('email_forcedUnchecked')) {
                newRow.email = singleData.email;
            } else if (!singleData['email_forcedUnchecked']) {
                newRow.email = singleData.email;
            }
        }

        if ( singleData.hasOwnProperty('number')) {
            if (!singleData.hasOwnProperty('number_forcedUnchecked')) {
                newRow.number = singleData.number;
            } else if (!singleData['number_forcedUnchecked']) {
                newRow.number = singleData.number;
            }
        }

        if ( singleData.hasOwnProperty('reference')) {
            if (!singleData.hasOwnProperty('reference_forcedUnchecked')) {
                newRow.reference = singleData.reference;
            } else if (!singleData['reference_forcedUnchecked']) {
                newRow.reference = singleData.reference;
            }
        }

        if ( singleData.hasOwnProperty('address')) {
            if (!singleData.hasOwnProperty('address_forcedUnchecked')) {
                newRow.address = singleData.address;
            } else if (!singleData['address_forcedUnchecked']) {
                newRow.address = singleData.address;
            }
        }

        if ( singleData.hasOwnProperty('expiry')) {
            if (!singleData.hasOwnProperty('expiry_forcedUnchecked')) {
                newRow.expiry = singleData.expiry;
            } else if (!singleData['expiry_forcedUnchecked']) {
                newRow.expiry = singleData.expiry;
            }
        }

        if ( singleData.hasOwnProperty('pin')) {
            if (!singleData.hasOwnProperty('pin_forcedUnchecked')) {
                newRow.pin = singleData.pin;
            } else if (!singleData['pin_forcedUnchecked']) {
                newRow.pin = singleData.pin;
            }
        }

        if ( singleData.hasOwnProperty('uid')) {
            if (!singleData.hasOwnProperty('uid_forcedUnchecked')) {
                newRow.uid = singleData.uid;
            } else if (!singleData['uid_forcedUnchecked']) {
                newRow.uid = singleData.uid;
            }
        }

        if (currentListType == "makeNewList") {
            if (componentCalledSource === "sms_emailChannel") {
                newRow.type = "sms_email";
            } else if (componentCalledSource === "smsChannel") {
                newRow.type = "sms";
            } else if (componentCalledSource === "emailChannel") {
                newRow.type = "email";
            }
        } else {
            newRow.type = currentListType;
        }

        return newRow;
    }

    const saveAllContacts = (saveInDatabase = "yes", newListName ="") => {
        let dataArray = [];
        browseFileData.map((singleData) => {
            dataArray.push(getSingleRowData(singleData, newListName));
        });

        if (saveInDatabase === "yes") {
            axios.post("/save-imported-contacts", dataArray).then(res => {
                if (res.data.status === "success") {
                    if (componentCalledSource === "contactList") {
                        alert(res.data.message);
                        history.push(`/edit-contact-list/${currentListID}`);
                    } else {
                        alert(res.data.message);
                        props.getUploadedContactData(dataArray);
                    }
                } else {
                    alert("Something went wrong adding list!");
                }
            }).catch(err => {
                alert(err?.response?.data?.message || "Something went wrong adding list!");
                console.error(err)
            })
        } else {
            props.getUploadedContactData(dataArray);
        }
    }

    const renameDataKey = (oldKeyName, newKeyName, singleRowObj) => {
        let newWordsObject = {};
        console.log("singleRowObj", singleRowObj);
        Object.keys(singleRowObj).forEach(key => {
            if (key === oldKeyName) {
                let newPair = { [newKeyName]: singleRowObj[oldKeyName] };
                newWordsObject = { ...newWordsObject, ...newPair }
            } else {
                newWordsObject = { ...newWordsObject, [key]: singleRowObj[key] }
            }
        });
        newWordsObject[newKeyName + "_forcedUnchecked"] = false;
        return newWordsObject;
    }

    const updateDataColumn = (columnNeedUpdate, columnSelected) => {
        let oldKeyName = columnNeedUpdate.headerKeyName;
        let newKeyName = columnSelected;
        console.log('browseFileData', browseFileData);
        let newRowData = browseFileData.map((rowObject) => {
            return renameDataKey(oldKeyName, newKeyName, rowObject);
        });
        setBrowseFileData(newRowData);
    }

    switch (currentStep) {
        case "S1_selectMethod" :
            stepView =  <SelectMethod
                    continueHandler={continueHandler}
                    importOptionSelected={importOptionSelected}
                    setImportOptionSelected={setImportOptionSelected}
                    componentCalledSource={componentCalledSource}
                    setUpdateModalSize={props.setUpdateModalSize}
                />;
            break;
        case "S2_browseFile" :
            stepView =  <BrowseFile
                    browseFileData={browseFileData}
                    setBrowseFileData={setBrowseFileData}
                    continueHandler={continueHandler}
                    handleBackClick={handleBackClick}
                    componentCalledSource={componentCalledSource}
                    setUpdateModalSize={props.setUpdateModalSize}
                />;
            break;
        case "S2_copyPasteFile" :
            stepView =  <CopyPasteFile
                    browseFileData={browseFileData}
                    setBrowseFileData={setBrowseFileData}
                    continueHandler={continueHandler}
                    handleBackClick={handleBackClick}
                    componentCalledSource={componentCalledSource}
                    setUpdateModalSize={props.setUpdateModalSize}
                />;
            break;
        case "S2_selectContactList" :
            stepView =  <SelectContactList
                    browseFileData={browseFileData}
                    setBrowseFileData={setBrowseFileData}
                    continueHandler={continueHandler}
                    handleBackClick={handleBackClick}
                    componentCalledSource={componentCalledSource}
                    setUpdateModalSize={props.setUpdateModalSize}
                    getUploadedContactData={props.getUploadedContactData}
                />;
            break;
        case "S3_mappingColumns" :
            stepView =  <MappingColumns
                    continueHandler={continueHandler}
                    browseFileData={browseFileData}
                    updateDataColumn={updateDataColumn}
                    setBrowseFileData={setBrowseFileData}
                    componentCalledSource={componentCalledSource}
                    handleBackClick={handleBackClick}
                    setUpdateModalSize={props.setUpdateModalSize}
                />;
            break;
        default :
            stepView =  <SelectMethod
                    continueHandler={continueHandler}
                    importOptionSelected={importOptionSelected}
                    setImportOptionSelected={setImportOptionSelected}
                    componentCalledSource={componentCalledSource}
                    setUpdateModalSize={props.setUpdateModalSize}
                />;
            break;
    }

    return (
        <div>
            {(componentCalledSource === "contactList") &&
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="mb-5">
                        <Link to={history.location.state?.listId ? `/edit-contact-list/${currentListID}` : "/contacts"}>
                        Contacts
                        </Link>
                        <Typography color="text.primary">Import Contacts</Typography>
                    </Breadcrumbs>
                    <Container fixed>
                        { stepView }
                    </Container>
                </>
            }

            {(componentCalledSource !== "contactList") &&
                <Grid container>
                    { stepView }
                </Grid>
            }
        </div>
    )
}