import './importcontacts.css';
import axios from "../../../../helpers/request";
import { useAppState } from "../../../../state";

import React, { useState, useEffect } from "react";
import { Grid, TextField } from '@mui/material';
import { array } from 'i/lib/util';

export default function CopyPasteFile(props) {
    const [contactList, setContactList] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const { user: loggedUser } = useAppState();
    const companyId = loggedUser.id;

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const contacts = allContacts.filter((ele) => selectedList == ele.listId);
        props.getUploadedContactData(contacts);
    };

    // Get table colms from db to display while edit table header
    const getAllContactList = () => {
        axios.get(`/get-all-contact-list/${companyId}`)
            .then((res) => {
                let templist = [];
                let templistIds = [];
                let allData = res.data.filter((item) =>{
                    if (props.componentCalledSource == 'emailChannel') {
                        return (item.list_type == 'email' || item.list_type == 'sms_email')
                    } else if (props.componentCalledSource == 'smsChannel') {
                        return (item.list_type == 'sms' || item.list_type == 'sms_email');
                    } else {
                        return (item.list_type == 'email' || item.list_type == 'sms' || item.list_type == 'sms_email');
                    }
                });
                setAllContacts(allData);
                allData.map((item) => {
                    if (item.listId) {
                        if (!templistIds.includes(item.listId)) {
                            templistIds.push(item.listId);
                            templist.push({'id': item.listId, 'name': item.list_name});
                        }
                    }
                });
                console.log("templist", templist);
                setContactList(templist);
            })
            .catch((err) => {
                console.error("errr", err);
            });
    }

    useEffect(() => {
        if (props.componentCalledSource !== "contactList") { props.setUpdateModalSize(false); }
        getAllContactList();
    }, [props.browseFileData]);

    return (
        <>
            <span className='import-contact-header'>
                Select contact list
            </span>

            <Grid container className="mt-20">
                <Grid item xs={12} md={12} lg={12}>
                    <select class="popupDropdown"  
                        onChange={(event) => {
                            setSelectedList(event.target.value);
                        }}
                    >
                        <option>Select option</option>
                        { contactList.map((list) => {
                            return (<option value={list.id} key={list.id}>{list.name}</option>);
                        })}
                    </select>
                </Grid>
            </Grid>

            <button
                onClick={(e) => handleOnSubmit(e) }
                className="btn btn-primary text-white br-10 importsubmit"
            >Submit</button>

            <button
                onClick={() => props.handleBackClick()}
                className="btn btn-white-red br-10 importsubmit ml-20"
            >Back</button>
        </>
    )
}