import './analytics.css'
import { BarChartOutlined, VisibilityOutlined, DeleteOutlined, CloudDownloadOutlined } from '@material-ui/icons'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { useState, useEffect } from 'react';
import axios from '../../../helpers/request';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { Grid } from '@mui/material';
import { useAppState } from '../../../state';
import Button from '@material-ui/core/Button';

function CustomToolbar() {
  return (
    <div className="d-flex justify-content-end">
      <span className='btn btn-primary text-white'>
        <CloudDownloadOutlined />&nbsp; Export
      </span>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Analytics() {
  const { user: loggedUser } = useAppState();
  const [data, setData] = useState({ data: [], total: 0 });

  // const [emailData, setEmailData] = useState({ data: [], total: 0 });
  // const [wpData, setWpData] = useState({ data: [], total: 0 });
  // const [tabValue, setTabValue] = useState(0);
  const [analyticsType, setAnalyticsType] = useState('');

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [disableExport, setDisableExport] = useState(false);

  const datacolumns = [
    "id",
    "campaignname",
    "campaigntype",
    "datetimesent",
    "sentby",
    "total",
    "processed",
    "delivered",
    "failed",
    "scheduled"
  ];

  const getAnalytics = () => {
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize,
      analytics_type: analyticsType,
      companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
    };
    if (loggedUser.role === "superadmin") {
      searchParams.companyid = "superadmin"
    }
    axios.get('/analytics', { params: searchParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }

  useEffect(() => {
    getAnalytics()
  }, [pageSize, pageNumber, analyticsType])

  const handleAnalyticsTypeChange = (event) => {
    console.log("EVE:", event)
    setAnalyticsType(event.target.value);
    setPageNumber(1)
  }

  // const getAnalytics = () => {
  //   setData({
  //     data: [],
  //     total: 0
  //   })
  //   let searchParams = {
  //     page_number: pageNumber,
  //     page_size: pageSize,
  //     companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  //   };
  //   if (loggedUser.role === "superadmin") {
  //     searchParams.companyid = "superadmin"
  //   }
  //   axios.get('/message-analytics', { params: searchParams }).then(async (response) => {
  //     if (response.status === 200 && response.data) {
  //       setData(response.data)
  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // }

  // const getEmailAnalytics = () => {
  //   setEmailData({
  //     data: [],
  //     total: 0
  //   })
  //   let searchParams = {
  //     page_number: pageNumber,
  //     page_size: pageSize,
  //     companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  //   };
  //   if (loggedUser.role === "superadmin") {
  //     searchParams.companyid = "superadmin"
  //   }
  //   axios.get('/email-analytics', { params: searchParams }).then(async (response) => {
  //     if (response.status === 200 && response.data) {
  //       setEmailData(response.data)
  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // }

  // const getWpAnalytics = () => {
  //   setWpData({
  //     data: [],
  //     total: 0
  //   })
  //   let searchParams = {
  //     page_number: pageNumber,
  //     page_size: pageSize,
  //     companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  //   };
  //   if (loggedUser.role === "superadmin") {
  //     searchParams.companyid = "superadmin"
  //   }
  //   axios.get('/whatsapp-analytics', { params: searchParams }).then(async (response) => {
  //     if (response.status === 200 && response.data) {
  //       setWpData(response.data)
  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // }

  // useEffect(() => {
  //   if (tabValue === 0) {
  //     getAnalytics()
  //   } else if (tabValue === 1) {
  //     getEmailAnalytics()
  //   } else {
  //     getWpAnalytics()
  //   }
  // }, [tabValue, pageSize, pageNumber])

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   setPageNumber(1)
  // }

  // const columns = [
  //   { field: 'id', headerName: 'ID', width: 60, sortable: false },
  //   { field: 'campaignname', headerName: 'Campaign Name', minWidth: 200, flex: 0.5, sortable: false },
  //   // { field: 'smsbody', headerName: 'Message Body', flex: 1, minWidth: 300, sortable: false },
  //   {
  //     field: 'datetimesent', headerName: 'Date Sent', width: 170, type: 'dateTime',
  //     valueGetter: ({ value }) => value && new Date(value),
  //   },
  //   { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
  //   { field: 'totalmessages', headerName: 'Total', width: 85, sortable: false },
  //   { field: 'sent', headerName: 'Sent', width: 85, sortable: false },
  //   { field: 'delivered', headerName: 'Delivered', width: 85, sortable: false },
  //   { field: 'queued', headerName: 'Queued', width: 85, sortable: false },
  //   { field: 'undelivered', headerName: 'Undelivered', width: 85, sortable: false },
  //   { field: 'failed', headerName: 'Failed', width: 85, sortable: false },
  //   {
  //     field: 'scheduled', headerName: 'Scheduled', width: 100, sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <div>
  //           {params.row.scheduled ? "Yes" : "No"}
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     field: 'action',
  //     headerName: 'Action',
  //     width: 100,
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <div className='userListActions'>
  //           <Link to={'/campaigns-view/' + params.row.campaignid}>
  //             <button className="actionElementButton">
  //               <VisibilityOutlined className='actionElementIcon' />
  //               <span className="actionButtonText">View</span>
  //             </button>
  //           </Link>
  //         </div>
  //       )
  //     }
  //   }
  // ];

  // const wpColumns = [
  //   { field: 'id', headerName: 'ID', width: 60, sortable: false },
  //   {
  //     field: 'smsbody', headerName: 'Initial Message Body', flex: 1, minWidth: 300, sortable: false
  //   },
  //   {
  //     field: 'datetimesent', headerName: 'Date Sent', width: 170, type: 'dateTime',
  //     valueGetter: ({ value }) => value && new Date(value),
  //   },
  //   { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
  //   { field: 'sent', headerName: 'Sent', width: 85, sortable: false },
  //   { field: 'read', headerName: 'Read', width: 85, sortable: false },
  //   { field: 'delivered', headerName: 'Delivered', width: 85, sortable: false },
  //   { field: 'queued', headerName: 'Queued', width: 85, sortable: false },
  //   { field: 'undelivered', headerName: 'Undelivered', width: 85, sortable: false },
  //   { field: 'failed', headerName: 'Failed', width: 85, sortable: false },
  //   {
  //     field: 'action',
  //     headerName: 'Action',
  //     width: 100,
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <div className='userListActions'>
  //           <Link to={'/whatsapp-campaign/' + params.row.campaignid}>
  //             <button className="actionElementButton">
  //               <VisibilityOutlined className='actionElementIcon' />
  //               <span className="actionButtonText">View</span>
  //             </button>
  //           </Link>
  //         </div>
  //       )
  //     }
  //   }
  // ];

  // const emailColumns = [
  //   { field: 'id', headerName: 'ID', width: 60, sortable: false },
  //   { field: 'campaignname', headerName: 'Campaign Name', minWidth: 200, flex: 0.5, sortable: false },
  //   // {
  //   //   field: 'smsbody', headerName: 'Message Body', flex: 1, minWidth: 300, sortable: false,
  //   //   renderCell: (params) => {
  //   //     return (
  //   //       <span className="dataDescriptionSM" dangerouslySetInnerHTML={{ __html: params.row.smsbody }}></span>
  //   //     )
  //   //   }
  //   // },
  //   {
  //     field: 'datetimesent', headerName: 'Date Sent/Scheduled', width: 170, type: 'dateTime',
  //     valueGetter: ({ value }) => value && new Date(value),
  //   },
  //   { field: 'sentby', headerName: 'Created By', width: 140, sortable: false },
  //   { field: 'opens', headerName: 'Opens', width: 85, sortable: false },
  //   { field: 'clicks', headerName: 'Clicks', width: 85, sortable: false },
  //   { field: 'deferred', headerName: 'Defer', width: 85, sortable: false },
  //   { field: 'dropped', headerName: 'Drop', width: 85, sortable: false },
  //   { field: 'bounced', headerName: 'Bounce', width: 85, sortable: false },
  //   { field: 'processed', headerName: 'Sent', width: 85, sortable: false },
  //   { field: 'totalfails', headerName: 'Fails', width: 85, sortable: false },
  //   { field: 'totalmessages', headerName: 'Total', width: 85, sortable: false },
  //   {
  //     field: 'scheduled', headerName: 'Scheduled', width: 100, sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <div className='userListActions'>
  //           {params.row.scheduled ? "Yes" : "No"}
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     field: 'action',
  //     headerName: 'Action',
  //     width: 100,
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <div className='userListActions'>
  //           <Link to={'/email-campaign/' + params.row.campaignid}>
  //             <button className="actionElementButton">
  //               <VisibilityOutlined className='actionElementIcon' />
  //               <span className="actionButtonText">View</span>
  //             </button>
  //           </Link>
  //         </div>
  //       )
  //     }
  //   }
  // ];

  const objectToCsv = function (data) {

    const csvRows = [];

    /* Using push() method we push fetched
       data into csvRows[] array */
    csvRows.push(datacolumns.join(','));

    // Loop to get value of each objects key
    for (const row of data) {
      const values = datacolumns.map(header => {
        let val = row[header]
        if (header === "campaignname") {
          let newVal = val.replace(/,/g, ' ');
          return `${newVal}`;
        } else {
          return `${val}`;
        }
      });

      // To add, separator between each value
      csvRows.push(values.join(','));
    }

    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join('\n');
  };

  const download = function (data) {
    // Creating a Blob for having a csv file format  
    // and passing the data with type 
    const blob = new Blob([data], { type: 'text/csv' });
    // Creating an object for downloading url 
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML 
    const a = document.createElement('a')
    // Passing the blob downloading url  
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading 
    // and passing the download file name 
    a.setAttribute('download', 'analytics_' + new Date().getTime() + '.csv');
    // Performing a download with click 
    a.click()
  }

  const exportAllCampaigns = () => {
    let searchParams = {
      page_number: -1,
      page_size: -1,
      analytics_type: analyticsType,
      companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
    };
    setDisableExport(true);
    axios.get('/analytics', { params: searchParams }).then(async (response) => {
      // console.log("response", response);
      if (response.status === 200 && response.data) {
        let tempAllData = response.data.map((row) => {
          let camp_type = "";
          switch (row.type) {
            case 11:
              camp_type = "SMS & Email";
              break;
            case 2:
              camp_type = "WhatsApp";
              break;
            case 1:
              camp_type = 'Email';
              break;
            default:
              camp_type = 'SMS';
              break;
          }

          let tempTotal = row.type == 2 ? row.totalwpmessages : row.totalmessages;
          let tempProcessed = Number(row.processedEmail) + Number(row.processedSMS) + Number(row.processedWP);
          let tempDelivered = Number(row.deliveredEmail) + Number(row.deliveredSMS) + Number(row.deliveredWP);
          let tempBounced = Number(row.bouncedEmail) + Number(row.droppedEmail) + Number(row.failedSMS) + Number(row.failedWP);
          let tempScheduled = row.scheduled ? "Yes" : "No";
          // let dts = new Date(row.datetimesent);
          // let dateTimeSentFormat = [(dts.getMonth() + 1),
          // dts.getDate(),
          // dts.getFullYear()].join('/') + ' ' +
          //   [dts.getHours(),
          //   dts.getMinutes(),
          //   dts.getSeconds()].join(':');

          return {
            'id': row.id,
            'campaignname': row.campaignname,
            'campaigntype': camp_type,
            'datetimesent': row.datetimesent,
            'sentby': row.sentby,
            'total': tempTotal,
            'processed': tempProcessed,
            'delivered': tempDelivered,
            'failed': tempBounced,
            'scheduled': tempScheduled
          }
        });
        const content = objectToCsv(tempAllData);
        download(content);
        setDisableExport(false);
      } else {
        setDisableExport(false);
        throw new Error(response?.error);
      }
    });
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 60, sortable: false },
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 200, flex: 0.5, sortable: false },
    {
      field: 'type', headerName: 'Campaign Type', flex: 0.4, sortable: false,
      renderCell: (params) => {
        switch (params.row.type) {
          case 11:
            return <><Chip size="small" label="SMS" /><Chip size="small" label="Email" /></>;
          case 2:
            return <Chip size="small" label="Whatsapp" />;
          case 1:
            return <Chip size="small" label="Email" />;
          default:
            return <Chip size="small" label="SMS" />;
        }
      }
    },
    // { field: 'smsbody', headerName: 'Message Body', flex: 1, minWidth: 300, sortable: false },
    {
      field: 'datetimesent', headerName: 'Date Sent', width: 170, type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    },
    { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
    {
      field: 'totalmessages', headerName: 'Total', flex: 0.3, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.type == 2 ? params.row.totalwpmessages : params.row.totalmessages}
          </>
        )
      }
    },
    {
      field: 'processedEmail', headerName: 'Processed', flex: 0.3, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.processedEmail) + Number(params.row.processedSMS) + Number(params.row.processedWP)}
          </>
        )
      }
    },
    {
      field: 'deliveredEmail', headerName: 'Delivered', flex: 0.3, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.deliveredEmail) + Number(params.row.deliveredSMS) + Number(params.row.deliveredWP)}
          </>
        )
      }
    },
    {
      field: 'bouncedEmail', headerName: 'Failed', flex: 0.3, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.bouncedEmail) + Number(params.row.droppedEmail) + Number(params.row.failedSMS) + Number(params.row.failedWP)}
          </>
        )
      }
    },
    {
      field: 'scheduled', headerName: 'Scheduled', flex: 0.3, sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.scheduled ? "Yes" : "No"}
          </div>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='userListActions'>
            <Link to={'/campaign-detail/' + params.row.campaignid}>
              <button className="actionElementButton">
                <VisibilityOutlined className='actionElementIcon' />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
          </div>
        )
      }
    }
  ];

  return (
    <div>
      <Grid container spacing={2} className="ptb-0 main-grid-container">
        <Grid item xs={12} md={3} lg={3}>
          <div className='sendNowItem'>
            <label>Campaign Type</label>
            <select name="sendGridSenderId" className='popupDropdown' value={analyticsType} onChange={(event) => handleAnalyticsTypeChange(event)} >
              <option value="">All</option>
              <option value="11">SMS and Email</option>
              <option value="2">Whatsapp</option>
              <option value="1">Email</option>
              <option value="0">SMS</option>
            </select>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid item xs={12} md={3} lg={3}>
          {/* <button className="btn btn-primary text-white br-10 rightside-btn">Export</button> */}
        </Grid>
      </Grid>


      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          Toolbar: () => {
            return (
              <div className='tableButtonRow'>
                <span className='exportbutton'>
                  <Button disabled={disableExport} className='exportbutton_other' color="primary" onClick={exportAllCampaigns} >Export All</Button>
                </span>
              </div>
            );
          }
        }}
      />
      {/* <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="SMS Campaigns" id="simple-tab-sms" aria-controls="simple-tabpanel-sms" />
        <Tab label="Email Campaigns" id="simple-tab-email" aria-controls="simple-tabpanel-email" />
        <Tab label="Whatsapp" id="simple-tab-whatsapp" aria-controls="simple-tabpanel-whatsapp" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <DataGrid
          columns={columns}
          rows={data.data}
          rowCount={data.total}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          pagination
          paginationMode="server"
          checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DataGrid
          columns={emailColumns}
          rows={emailData.data}
          rowCount={emailData.total}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          pagination
          paginationMode="server"
          checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DataGrid
          columns={wpColumns}
          rows={wpData.data}
          rowCount={wpData.total}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          pagination
          paginationMode="server"
          checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </TabPanel> */}
    </div>
  )
}
