import "../../NewContact/newcontact.css";
import React, { useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";

import List from './list';
import CreateEdit from './createEdit';

export default function CreateContactList() {
    
    const location = useLocation();
    console.log("location", location);
    const isViewList = location.pathname.split("/").includes('view-contact-list');
    const isEditable = location.pathname.split("/").includes('edit-contact-list');
    const formPage = isEditable ? "edit-list": "create-list";
    const [currentPage, setCurrentPage] = useState(!isViewList ? formPage : "view-list");

    let viewPage = <></>;
    switch (currentPage) {
        case "create-list":
            viewPage = <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            break;
        case "edit-list":
            viewPage = <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            break;
        case "view-list":
            viewPage = <List currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            break;
        default:
            viewPage = <List currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            break;
    }

    return (
        <>
            {viewPage}
        </>
    )
}
