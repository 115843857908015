import axios from '../../../helpers/request';
import './templates.css'
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutlined, EditOutlined, VisibilityOutlined, AddOutlined, LayersOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAppState } from '../../../state';

export default function Templates() {

  const [data, setData] = useState([]);
  const { user } = useAppState();

  const handleDelete = (id, name) => {
    // setData(data.filter((item) => item.id !== id));
    const confirmDelete = window.confirm(`Are you sure to delete ${name}`);
    if (confirmDelete) {
      axios.delete(process.env.REACT_APP_API_BASEURL + '/template/' + id)
        .then(function (response) {
          getTemplates()
          alert("Template deleted successfully");
        })
        .catch(function (error) {
          alert(error?.response?.data?.message || "Unable to delete template");
        });
    }
  };


  const getTemplates = () => {
    console.log("USER::", user);
    let queryParams = {
      "companyId": user.added_by ? user.added_by : user.id
    }
    axios.get('/templates', { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }

  useEffect(() => {

    getTemplates()
  }, [])


  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },

  ]);


  const columns = [
    { field: 'id', headerName: 'ID', width: 90, sortable: false },
    { field: 'templatename', headerName: 'Template Name', width: 600, sortable: false },
    {
      field: 'lastmodified', headerName: 'Last Modified', width: 200, type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    },
    { field: 'lastmodifiedby', headerName: 'Last Modified By', width: 200, sortable: false },
    {
      field: 'type', headerName: 'Template Type', width: 200, sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {(params.row.type && params.row.type === "email") ? 'EMAIL' : 'SMS'}
          </div>
        )
      }
    },
    // { field: 'templatebody', headerName: 'Template Body', width: 200, sortable: false },
    {
      field: 'action',
      headerName: 'Action Taken',
      width: 400,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='userListActions'>
            <Link to={'/template-view/' + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className='actionElementIcon' />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={'/template-edit/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className='actionElementIcon' />
                <span className="actionButtonText">Edit</span></button>
            </Link>
            <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.templatename)} />
          </div>
        )
      }

    }

  ];
  return (
    <div>
      <div className="pageSubHeader flex-column">

        <div className="align-self-end">
          <Link to='/newtemplate'>
            <button className="btn btn-primary text-white br-10">Add</button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        autoHeight={true}
      />
    </div>
  )
}
