import "./overview.css";
import { DataGrid } from "@material-ui/data-grid";
import {
  ExitToAppOutlined,
  DirectionsWalkOutlined,
  DashboardOutlined,
  TextsmsOutlined,
  ChatOutlined,
  VoiceChatOutlined,
  Close,
  GroupOutlined,
  BarChartOutlined,
  WhatsApp,
  GroupAddOutlined,
  ScheduleOutlined,
  SendOutlined,
  LayersOutlined,
  Euro,
  MailOutlined,
} from "@material-ui/icons";
import { useState, useEffect, useRef } from "react";
import axios from "../../../helpers/request";
import fireicon from "../../images/firedashicon.svg";
import foodicon from "../../images/fooddashicon.svg";
import smsicon from "../../images/smsdashicon.svg";
import safetyicon from "../../images/safetydashicon.svg";
import remoteicon from "../../images/remotedashicon.svg";
import DoghuntHalfCharts from "./charts/doughnut-half-chart";
import PieCharts from "./charts/pie-chart";
import ReactSpeedometer from "react-d3-speedometer";
import SMSicon from "../../../components/images/smsicon.svg";
import Emailicon from "../../../components/images/emailicon.svg";
import Chaticon from "../../../components/images/chaticon.svg";
import Whatsappicon from "../../../components/images/whatsappicon.svg";
import Videoicon from "../../../components/images/videoicon.svg";
import CountUp from 'react-countup';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { useAppState } from "../../../state";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const options = {
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
  },
  redraw: true,
};

const defaultDataset = {
  labels,
  datasets: [
    {
      label: "Email",
      data: [],
      backgroundColor: "#52ace9",
      barThickness: 30,
      borderRadius: 5
    },
    {
      label: "SMS ",
      data: [],
      backgroundColor: "#da524c",
      barThickness: 35,
      borderRadius: 5
    },
  ],
};

export default function Overview() {
  let defaultSmsEmailstatusRate = {
      'sms': {
        'success': 0,
        'fail': 0
      },
      'email': {
        'success': 0,
        'fail': 0
      }
    };
  const { user: loggedUser } = useAppState();
  const [data, setData] = useState({ "TotalEmails": 0, "TotalMessages": 0 });
  const [wpData, setWPData] = useState({ "TotalMessages": 0 });
  const [smsSentData, setSmsSentData] = useState([]);
  const [emailSentData, setEmailSentData] = useState([]);
  const [smsEmailStatusRateData, setSmsEmailStatusRateData] = useState(defaultSmsEmailstatusRate);

  const [smsStatusRateData, setSmsStatusRateData] = useState(defaultSmsEmailstatusRate.sms);
  const [emailStatusRateData, setEmailStatusRateData] = useState(defaultSmsEmailstatusRate.email);
  const [reloadBarChart, setReloadBarChart] = useState(false);
  const [barCartData, setBarCartData] = useState(defaultDataset);
  const barChartRef = useRef(null);

  // const getLogs = () => {
  //   axios.get("/logs").then(async (response) => {
  //     if (response.status === 200 && response.data) {
  //       setData(response.data);
  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // };


  const getDashboardData = () => {
    let url = "/dashboard-analytics-all"
    if (loggedUser.role !== "superadmin") {
      url = "/dashboard-analytics/" + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    axios.get(url).then(async (response) => {
      if (response.status === 200 && response.data) {
        const { TotalEmails, TotalMessages } = response.data
        setData({ TotalEmails, TotalMessages });
      } else {
        throw new Error(response?.error);
      }
    });
  };

  /*const getDashboardDataWhatsapp = () => {
    let url = "dashboard-analytics-whatsapp-all"
    if (loggedUser.role !== "superadmin") {
      url = "/dashboard-analytics-whatsapp/" + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    axios.get(url).then(async (response) => {
      if (response.status === 200 && response.data) {
        const { TotalMessages } = response.data[0]
        setWPData({ TotalMessages });
      } else {
        throw new Error(response?.error);
      }
    });
  };*/

  const getAllServicesSent = () => {
    let url = "/dashboard-services-sent-all"
    if (loggedUser.role !== "superadmin") {
      url = "/dashboard-services-sent/" + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    axios.get(url).then(async (response) => {
      if (response.status === 200 && response.data) {
        let tempLabels = response.data.map((data) => allMonths[data.sent_month-1]);
        let sentMonths = [...new Set(response.data.map((data) => data.sent_month))];
        let firstIndexMonth = tempLabels[0];
        let lastIndexMonth = (tempLabels.length.length >1 ) ? tempLabels[tempLabels.length - 1]:  0;
        labels = [...new Set(tempLabels)];

        let smsData = response.data.filter((data) => data.type === 0);
        let emailData = response.data.filter((data) => data.type === 1);

        let smsSentMonth = smsData.map((data) => data.sent_month);
        let emailSentMonth = emailData.map((data) => data.sent_month);

        let smsCount = smsData.map((data) => data.count);
        let emailCount = emailData.map((data) => data.count);

        sentMonths.map((month, index) => {
          if (index === 0) {
            if (sentMonths[index] === smsSentMonth[index]) {
              smsCount.unshift(0);
            }
            if (sentMonths[index] === emailSentMonth[index]) {
              emailCount.unshift(0);
            }
          } else {
            if (!smsSentMonth.includes(month)) {
              smsCount.splice(index, 0, 0);
            }
            if (!emailSentMonth.includes(month)) {
              emailCount.splice(index, 0, 0);
            }
          }
        });

        if (firstIndexMonth === lastIndexMonth) {
          sentMonths.push(sentMonths[0]);
          labels.push(lastIndexMonth);
        }

        smsData = smsData.map((data) => data.count);
        emailData = emailData.map((data) => data.count);

        const tempchartData = {
          labels,
          datasets: [
            {
              label: "Email",
              data: emailData,
              backgroundColor: "#52ace9",
              barThickness: 30,
              borderRadius: 5
            },
            {
              label: "SMS ",
              data: smsData,
              backgroundColor: "#da524c",
              barThickness: 35,
              borderRadius: 5
            },
          ],
        };

        console.log("tempchartData", tempchartData);
        setBarCartData(tempchartData);

        //console.log("barChartRef", barChartRef);
        //setReloadBarChart(true);
        //barChartRef.current.draw();
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const getSmsEmailDeliveryFaiulreRates = () => {
    let smsUrl = "/dashboard-sms-delivery-failure-rate"
    if (loggedUser.role !== "superadmin") {
      smsUrl = "/company-dashboard-sms-delivery-failure-rate/" + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    axios.get(smsUrl).then(async (response) => {
      if (response.status === 200 && response.data) {
        let allSMSData = response.data;

        // Process to calculate percentages for SMS delivery/fail
        let smsSuccessPercentages = 0;
        let smsFailPercentages = 0;
        let smsSuccessData = allSMSData.filter((item) => item.delivered);
        let smsFailData = allSMSData.filter((item) => !item.delivered);
        smsSuccessPercentages = ((smsSuccessData.length * 100) / allSMSData.length).toFixed(2);
        smsFailPercentages = ((smsFailData.length * 100) / allSMSData.length).toFixed(2);

        let newPercentages = {'success': smsSuccessPercentages,'fail': smsFailPercentages};
        setSmsStatusRateData(newPercentages);
        console.log("smsFailData", smsFailData);
      } else {
        throw new Error(response?.error);
      }
    });

    let emailUrl = "/dashboard-email-delivery-failure-rate"
    if (loggedUser.role !== "superadmin") {
      emailUrl = "/company-dashboard-email-delivery-failure-rate/" + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    axios.get(emailUrl).then(async (response) => {
      if (response.status === 200 && response.data) {
        let allEmailData = response.data;

        // Process to calculate percentages for Email delivery/fail
        let emailSuccessPercentages = 0;
        let emailFailPercentages = 0;
        let emailSuccessData = allEmailData.filter((item) => item.delivered);
        let emailFailData = allEmailData.filter((item) => !item.delivered);
        emailSuccessPercentages = ((emailSuccessData.length * 100) / allEmailData.length).toFixed(2);
        emailFailPercentages = ((emailFailData.length * 100) / allEmailData.length).toFixed(2);

        let newPercentages = {'success': emailSuccessPercentages,'fail': emailFailPercentages};
        setEmailStatusRateData(newPercentages);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  useEffect(() => {
    // getLogs();
    getDashboardData()
    //getDashboardDataWhatsapp()
    getAllServicesSent()
    getSmsEmailDeliveryFaiulreRates()
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  return (
    <div>
      <Grid container justifyContent="center" spacing={2} className="dashboardboxsmallcontainer">
        <Grid item xs={12} md className="dashboardboxsmall">
          <img src={SMSicon} width="300" />
          <h2>SMS</h2>
          <p>Total SMS Sent</p>
          <h3><CountUp end={data.TotalMessages} /></h3>
        </Grid>
        <Grid item xs={12} md className="dashboardboxsmall">
          <img src={Emailicon} width="300" />
          <h2>Email</h2>
          <p>Total Emails Sent</p>
          <h3><CountUp end={data.TotalEmails} /></h3>
        </Grid>
        {/*<Grid xs={12} md className="dashboardboxsmall">
          <img src={Chaticon} width="300" />
          <h2>Chat</h2>
          <p>Total Conversations Had</p>
          <h3><CountUp end={987} /></h3>
        </Grid>
        <Grid xs={12} md className="dashboardboxsmall">
          <img src={Whatsappicon} width="300" />
          <h2>Whatsapp </h2>
          <p>Total Messages</p>
          <h3><CountUp end={wpData.TotalMessages} /></h3>
        </Grid>
        <Grid xs={12} md className="dashboardboxsmall">
          <img src={Videoicon} width="300" />
          <h2>Video</h2>
          <p>Total Video Calls Joined</p>
          <h3><CountUp end={1159} /></h3>
        </Grid>*/}
      </Grid>
        
      {/* 
        <div className="dashboardboxsmalllarge">
          <Grid container spacing={1} className="dashboard-charts-section">
            <Grid xs={12} md className="dashboard-charts-inner">
              <ReactSpeedometer
                value={222}
                ringWidth={10}
                height={200}
                maxSegmentLabels={5}
                needleHeightRatio={0.5}
                currentValueText=" "
                needleTransitionDuration={3333}
                segments={5}
                customSegmentLabels={[
                  {
                    text: "Sent",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "Delivered",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "Failed",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                ]}
              />
              <div className="speedo-text-container">
                <h2>SMS</h2>
                <p>Usage stats for the last 30 days</p>
              </div>
            </Grid>
            <Grid xs={12} md className="dashboard-charts-inner">
              <ReactSpeedometer
                value={222}
                ringWidth={10}
                height={200}
                maxSegmentLabels={5}
                needleHeightRatio={0.5}
                currentValueText=" "
                needleTransitionDuration={3333}
                segments={5}
                customSegmentLabels={[
                  {
                    text: "Low",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "Medium",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "High!",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                ]}
              />
              <div className="speedo-text-container">
                <h2>Email</h2>
                <p>Lorem Ipsum is simply Lorem Ipsum is simply</p>
              </div>
            </Grid>
            <Grid xs={12} md className="dashboard-charts-inner">
              {
                // <PieCharts id="chart3" />
              }
              <CircularProgressbarWithChildren
                className="test"
                strokeWidth={5}
                text={"66%"}
                styles={{
                  root: {
                    height: "250px",
                    width: "auto",
                  },
                }}
                value={66}
              >
                {
                // <div style={{ marginTop: "70%" }}>
                //  <h1 style={{ fontSize: "24px" }}>66%</h1>
                //  <p style={{ fontSize: "10px" }}>Day Score Last Incident</p>
                //</div> 
                }
              </CircularProgressbarWithChildren>
            </Grid>
            <Grid xs={12} md className="dashboard-charts-inner">
              <ReactSpeedometer
                value={222}
                ringWidth={10}
                height={200}
                maxSegmentLabels={5}
                needleHeightRatio={0.5}
                currentValueText=" "
                needleTransitionDuration={3333}
                segments={5}
                customSegmentLabels={[
                  {
                    text: "Low",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "Medium",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "High!",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                ]}
              />
              <div className="speedo-text-container">
                <h2>Chat</h2>
                <p>Lorem Ipsum is simply Lorem Ipsum is simply</p>
              </div>
            </Grid>
            <Grid xs={12} md className="dashboard-charts-inner">
              <ReactSpeedometer
                value={222}
                ringWidth={10}
                height={200}
                maxSegmentLabels={5}
                needleHeightRatio={0.5}
                currentValueText=" "
                needleTransitionDuration={3333}
                segments={5}
                customSegmentLabels={[
                  {
                    text: "Low",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "Medium",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                  {},
                  {
                    text: "High!",
                    position: "OUTSIDE",
                    color: "#A1A5B6",
                  },
                ]}
              />
              <div className="speedo-text-container">
                <h2>Whatsapp</h2>
                <p>Lorem Ipsum is simply Lorem Ipsum is simply</p>
              </div>
            </Grid>
          </Grid>
        </div>
      */}

      <Grid container>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">SMS Delivery and Failure Rate Past 30 Days</span>
          </div>
          <div className="completion-response-chart">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Delivered</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={smsStatusRateData.success == 'NaN'? '0%' : smsStatusRateData.success + "%"}
                  styles={{
                    root: {
                      height: "250px",
                      width: "auto",
                    },
                    path: {
                      stroke: "#27AE60",
                    },
                    trail: {
                      stroke: "#9bedbd",
                    },
                  }}
                  value={smsStatusRateData.success}
                >
                  {/* <div>
                  <h1 style={{ fontSize: "24px" }}>75%</h1>
                  <p style={{ fontSize: "10px" }}>Lorem Ipsum</p>
                </div> */}
                </CircularProgressbarWithChildren>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Failed</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={smsStatusRateData.fail == 'NaN'? '0%' : smsStatusRateData.fail + "%"}
                  styles={{
                    root: {
                      height: "250px",
                      width: "auto",
                    },
                    path: {
                      stroke: "#ee5864",
                    },
                    trail: {
                      stroke: "#f8b9bd",
                    },
                  }}
                  value={smsStatusRateData.fail}
                >
                  {/* <div>
                  <h1 style={{ fontSize: "24px" }}>20%</h1>
                  <p style={{ fontSize: "10px" }}>Lorem Ipsum</p>
                </div> */}
                </CircularProgressbarWithChildren>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">Email Delivery and Failure Rate Past 30 Days</span>
          </div>
          <div className="completion-response-chart">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Delivered</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={emailStatusRateData.success == 'NaN'? '0%' : emailStatusRateData.success + "%"}
                  styles={{
                    root: {
                      height: "250px",
                      width: "auto",
                    },
                    path: {
                      stroke: "#27AE60",
                    },
                    trail: {
                      stroke: "#9bedbd",
                    },
                  }}
                  value={emailStatusRateData.success}
                >
                  {/* <div>
                  <h1 style={{ fontSize: "24px" }}>75%</h1>
                  <p style={{ fontSize: "10px" }}>Lorem Ipsum</p>
                </div> */}
                </CircularProgressbarWithChildren>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Failed</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={emailStatusRateData.fail == 'NaN'? '0%' : emailStatusRateData.fail + "%"}
                  styles={{
                    root: {
                      height: "250px",
                      width: "auto",
                    },
                    path: {
                      stroke: "#ee5864",
                    },
                    trail: {
                      stroke: "#f8b9bd",
                    },
                  }}
                  value={emailStatusRateData.fail}
                >
                  {/* <div>
                  <h1 style={{ fontSize: "24px" }}>20%</h1>
                  <p style={{ fontSize: "10px" }}>Lorem Ipsum</p>
                </div> */}
                </CircularProgressbarWithChildren>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">Services Send Rates Year to date</span>
          </div>
          <Bar
            className="bar-chart completion-response-chart"
            options={options}
            data={barCartData}
            redraw={reloadBarChart}
          />
        </Grid>
      </Grid>
    </div>
  );
}
