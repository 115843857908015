import { LayersOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import "./campaigndetail.css";
import { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "../../../helpers/request";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { Breadcrumbs, Typography } from "@material-ui/core";

import SentsTable from "./SentsTable";
import EmailsTable from "./EmailsTable";
import ConversationsTable from "./ConversationsTable";

export default function CampaignDetail() {
  const { campaignId } = useParams();
  const location = useLocation();
  const [adding, setAdding] = useState(false);

  const [campaign, setCampaign] = useState({});

  const [campaignTotalCount, setCampaignTotalCount] = useState(0);
  const [campaignErrorCount, setCampaignErrorCount] = useState(0);

  const [campaignEmailTotalCount, setCampaignEmailTotalCount] = useState(0);
  const [campaignEmailErrorCount, setCampaignEmailErrorCount] = useState(0);

  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataSets, setChartDataSets] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [], options: { responsive: true } });

  const getCampaigns = () => {
    axios.get("/analytic-detail/" + campaignId).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log("analytic-detail", response.data[0])
        setCampaign(response.data[0]);
        setCampaignCounts(response.data[0]);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const setCampaignCounts = (campaignData) => {
    const { type } = campaignData;
    const { failedSMS, droppedEmail, bouncedEmail, failedWP, deliveredEmail, deliveredSMS, deliveredWP } = campaignData;

    if (type == 11) {
      // setCampaignTotalCount(Number(totalsents))
      // setCampaignErrorCount(Number(failedSMS));
      // setCampaignEmailTotalCount(totalemails)
      // setCampaignEmailErrorCount(Number(droppedEmail) + Number(bouncedEmail));
      setChartLabels(["SMS Sent", "SMS Failed", "Email Sent", "Email Failed"])
      setChartDataSets([
        {
          label: "",
          data: [Number(deliveredSMS), Number(failedSMS), 0, 0],
          backgroundColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)", "#46BFBD", "rgba(220, 67, 76, 1)"],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 5,
        },
        {
          label: "",
          data: [0, 0, Number(deliveredEmail), Number(droppedEmail) + Number(bouncedEmail)],
          backgroundColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)", "#46BFBD", "rgba(220, 67, 76, 1)"],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 5,
        }
      ])
    } else if (type == 2) {
      // setCampaignTotalCount(Number(totalwpmessages))
      // setCampaignErrorCount(Number(failedWP));
      setChartLabels(["Whatsapp Sent", "Whatsapp Failed"])
      setChartDataSets([
        {
          // label: "# of Messages",
          data: [Number(deliveredWP), Number(failedWP)],
          backgroundColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 5,
        }
      ])
    } else if (type == 1) {
      // setCampaignTotalCount(Number(totalemails))
      // setCampaignErrorCount(Number(droppedEmail) + Number(bouncedEmail));
      setChartLabels(["Email Sent", "Email Failed"])
      setChartDataSets([
        {
          // label: "# of Messages",
          data: [Number(deliveredEmail), Number(droppedEmail) + Number(bouncedEmail)],
          backgroundColor: ["#46BFBD", "rgba(220, 67, 76, 1)"],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 5,
        }
      ])
    } else if (type == 0) {
      // setCampaignTotalCount(Number(totalsents))
      // setCampaignErrorCount(Number(failedSMS));
      setChartLabels(["SMS Sent", "SMS Failed"])
      setChartDataSets([
        {
          // label: "# of Messages",
          data: [Number(deliveredSMS), Number(failedSMS)],
          backgroundColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 5,
        }
      ])
    }
  }

  // const campaigns = () => {
  //   return fetch(process.env.REACT_APP_API_BASEURL + '/analytics', {
  //     method: 'GET',
  //     headers: {
  //       'content-type': 'application/json',
  //     }
  //   });
  // }

  // const getCampaigns = () => {
  //   campaigns().then(async (response) => {
  //     const jsonResponse = await response.json();
  //     if (response.ok) {
  //       // setData(jsonResponse)
  //       // applyTemplate()

  //       const selectedCampaign = jsonResponse.filter(campaign => campaign.id == campaignId)

  //       if (selectedCampaign && selectedCampaign[0]) {
  //         // setTemplate(selectedTemplate[0]);
  //         setCampaign(prevCampaign => ({
  //           ...prevCampaign, campaignname: selectedCampaign[0].campaignname, sentby: selectedCampaign[0].sentby, datetimesent: selectedCampaign[0].datetimesent, totalmessages: selectedCampaign[0].totalmessages, totalsent: selectedCampaign[0].totalsent, totalfails: selectedCampaign[0].totalfails, campaignid: selectedCampaign[0].campaignid, errorCount: selectedCampaign[0].errorCount, sentCount: selectedCampaign[0].sentCount, totalCount: selectedCampaign[0].totalCount, smsbody: selectedCampaign[0].smsbody
  //         }));
  //       }

  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // }

  useEffect(() => {
    getCampaigns();

    ChartJS.register(ArcElement, Tooltip, Legend);

  }, []);

  useEffect(() => {
    setChartData({
      labels: chartLabels,
      datasets: chartDataSets,
      options: {
        responsive: true
      }
    })
  }, [chartLabels, chartDataSets])

  // const data = ;

  // const emailData = {
  //   labels: ["Sent", "Failed"],
  //   datasets: [
  //     {
  //       label: "# of Messages",
  //       data: [campaignEmailTotalCount, campaignEmailErrorCount],
  //       backgroundColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
  //       borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytics">
          Analytics
        </Link>
        <Typography color="text.primary">Campaign Details</Typography>
      </Breadcrumbs>

      <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <div className="campaignItem">
            <label>Campaign Name</label>
            <span className="dataDescriptionSM">{campaign.campaignname}</span>
          </div>
          <div className="campaignItem">
            <label>Sent By</label>
            <span className="dataDescriptionSM">{campaign.sentby}</span>
          </div>
          <div className="campaignItem">
            <label>Date Sent</label>
            <span className="dataDescriptionSM">
              {campaign.datetimesent ? moment(campaign.datetimesent).format("DD/MM/YYYY hh:mm") : ""}
            </span>
          </div>
          <div className="campaignItem">
            <label>Total Messages</label>
            <span className="dataDescriptionLG">{campaign.type == 2 ? campaign.totalwpmessages : campaign.totalmessages}</span>
          </div>
          {
            (campaign.type == 0 || campaign.type == 11) &&
            <>
              <div className="campaignItem">
                <label>Total SMS Processed</label>
                <span className="dataDescriptionLG">{Number(campaign.processedSMS)}</span>
              </div>
              <div className="campaignItem">
                <label>Total SMS Delivered</label>
                <span className="dataDescriptionLG">{Number(campaign.deliveredSMS)}</span>
              </div>
              {/* <div className="campaignItem">
                  <label>Total SMS Read</label>
                  <span className="dataDescriptionLG">{Number(campaign.readSMS)}</span>
                </div> */}
              <div className="campaignItem">
                <label>Total SMS Failed</label>
                <span className="dataDescriptionLG">{Number(campaign.failedSMS)}</span>
              </div>
            </>
          }
          {
            (campaign.type == 1 || campaign.type == 11) &&
            <>
              <div className="campaignItem">
                <label>Total Email Processed</label>
                <span className="dataDescriptionLG">{Number(campaign.processedEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Delivered</label>
                <span className="dataDescriptionLG">{Number(campaign.deliveredEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Clicked</label>
                <span className="dataDescriptionLG">{Number(campaign.clickedEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Opened</label>
                <span className="dataDescriptionLG">{Number(campaign.openedEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Deferred</label>
                <span className="dataDescriptionLG">{Number(campaign.deferredEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Bounced</label>
                <span className="dataDescriptionLG">{Number(campaign.bouncedEmail)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Email Dropped</label>
                <span className="dataDescriptionLG">{Number(campaign.droppedEmail)}</span>
              </div>
            </>
          }
          {
            campaign.type == 2 &&
            <>
              <div className="campaignItem">
                <label>Total Messages Processed</label>
                <span className="dataDescriptionLG">{Number(campaign.processedWP)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Messages Delivered</label>
                <span className="dataDescriptionLG">{Number(campaign.deliveredWP)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Messages Read</label>
                <span className="dataDescriptionLG">{Number(campaign.readWP)}</span>
              </div>
              <div className="campaignItem">
                <label>Total Messages Failed</label>
                <span className="dataDescriptionLG">{Number(campaign.failedWP)}</span>
              </div>
            </>
          }
        </form>
        <div className="campaigndetailschart">
          <Doughnut data={chartData} />
        </div>
      </div>
      {/* <h5>{(campaign.type == 0 || campaign.type == 11) ? 'SMS Analytics' : campaign.type == 1 ? 'Email Analytics' : campaign.type == 2 ? 'Whatsapp Analytics' : ''}</h5> */}
      {/* <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <>

          </>
          
        </form>

      </div> */}
      <div className="userTables">
        <div className="userTablesTop">
          {(campaign.type == 0 || campaign.type == 11) && <SentsTable campaignid={campaign.campaignid} />}
          {(campaign.type == 1 || campaign.type == 11) && <EmailsTable campaignid={campaign.campaignid} />}
          {(campaign.type == 2) && <ConversationsTable campaignid={campaign.campaignid} />}
        </div>
        {/* <div className="userTablesBottom">
          <SentsTable campaignid={campaign.campaignid} />
        </div> */}
      </div>

      {/* {campaign.type == 11 && <>
        <hr />
        <h5> Email Analytics </h5>
        <div className="campaigndetailscontainer">
          <form className="campaignForm">
            <div className="campaignItem">
              <label>Total Processed</label>
              <span className="dataDescriptionLG">{Number(campaign.processedEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Delivered</label>
              <span className="dataDescriptionLG">{Number(campaign.deliveredEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Clicked</label>
              <span className="dataDescriptionLG">{Number(campaign.clickedEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Opened</label>
              <span className="dataDescriptionLG">{Number(campaign.openedEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Deferred</label>
              <span className="dataDescriptionLG">{Number(campaign.deferredEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Bounced</label>
              <span className="dataDescriptionLG">{Number(campaign.bouncedEmail)}</span>
            </div>
            <div className="campaignItem">
              <label>Total Dropped</label>
              <span className="dataDescriptionLG">{Number(campaign.droppedEmail)}</span>
            </div>
          </form>
        </div>
        <div className="userTables">
          <div className="userTablesTop">
            <EmailsTable campaignid={campaign.campaignid} />
          </div>
        </div>
      </>} */}
    </div >
  );
}
