import axios from "../../helpers/request";
import "./ambassadors.css";
import { DataGrid } from "@material-ui/data-grid";
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
  AddOutlined,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";

export default function Ambassadors({ userDetails }) {
  const [data, setData] = useState([]);
  const cookies = new Cookies();

  const user_data_cookies = cookies.get("cs_user");

  const handleDelete = (id, name) => {
    // setData(data.filter((item) => item.id !== id));
    const confirmDelete = window.confirm(`Are you sure to delete ${name}`);
    if (confirmDelete) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + "/user/" + id)
        .then(function (response) {
          getAmbassadors();
          alert("User deleted successfully");
        })
        .catch(function (error) {
          alert(error?.response?.data?.message || "Unable to delete user");
        });
    }
  };

  const getAmbassadors = () => {
    if (user_data_cookies.role === "admin") {
      const data1 = userDetails?.filter((d) => {
        return d.role === "ambassador";
      });
      if (data1.length > 0) {
        return setData(data1);
      } 
    }else {
      axios.get("/ambassadors").then(async (response) => {
        if (response.status === 200 && response.data) {
          setData(response.data);
        } else {
          throw new Error(response?.error);
        }
      });
    }
  };

  useEffect(() => {
    getAmbassadors();
  }, [userDetails]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "fullName", headerName: "Full Name", width: 200 },
    { field: "email", headerName: "Email Address", width: 300 },
    { field: "companyName", headerName: "Company Name", width: 300 },
    { field: "username", headerName: "Username", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="userListActions">
            <Link to={"/user-view/" + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={"/user-edit/" + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>

            <DeleteOutlined
              className="userListDelete"
              onClick={() => handleDelete(params.row.id, params.row.fullName)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="ambassadors">
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Team Members</h3>
        <div className="align-self-end">
          <Link to="/newUser/ambassador">
          <button className="btn btn-primary text-white br-10">Add</button>
          </Link>
        </div>
      </div>

      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  );
}
