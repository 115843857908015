import { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

// import axios from "axios";

import "./login.css";
import logo from "../images/ProntobyKaptea.svg";
import graphic from "../images/logingraphic2023.svg";
import or from "../images/or.svg";
import CustomPopup from "../Popup/CustomPopup";
// import {templatesRows} from '../../dummyData';
import { useAppState } from "../../state";
import { useGoogleLogin } from "@moeindana/google-oauth";
import axios from "axios";

function Login() {
  const { signIn, user, isLoggedIn } = useAppState();

  const history = useHistory();
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Guser, setGUser] = useState();
  const [profile, setProfile] = useState([]);

  const [emailAddress, setEmailAddress] = useState("");
  const [otp, setOtp] = useState("");

  const [forgotPasswordPopup, setforgotPasswordPopup] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params["verification-success"]) {
      history.push("/login")
      alert("Thank you for verifying your mail, you can now login")
    }
  }, [])

  const openForgotPasswordPopup = (event) => {
    event.preventDefault();
    setforgotPasswordPopup(true);
  };

  const closeForgotPasswordPopup = () => {
    setforgotPasswordPopup(false);
    setStep(0);
    setEmailAddress("");
    setOtp("");
  };

  const verifyEmail = (email) => {
    return fetch(process.env.REACT_APP_API_BASEURL + "/verify-email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
  };

  const verifyOTP = (email, otp) => {
    return fetch(process.env.REACT_APP_API_BASEURL + "/verify-otp", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        otp: otp,
      }),
    });
  };

  const goToNextStep = () => {
    verifyEmail(emailAddress).then(async (response) => {
      const jsonResponse = await response.json();
      if (response.ok) {
        setStep(1);
      } else {
        if (jsonResponse?.message) {
          alert(jsonResponse.message);
        }
        throw new Error(response?.message);
      }
    });
  };

  const verifyOtp = () => {
    verifyOTP(emailAddress, otp).then(async (response) => {
      const jsonResponse = await response.json();
      console.log("jsonResponse::", jsonResponse);
      if (response.ok) {
        closeForgotPasswordPopup();
        history.push({
          pathname: "/passwordreset",
          state: { token: jsonResponse.token },
        });
      } else {
        if (jsonResponse?.message) {
          alert(jsonResponse.message);
        }
        throw new Error(response?.message);
      }
    });
  };

  const login = (event) => {
    event.preventDefault();
    signIn(username, password).then(() => {
      history.replace(location?.state?.from || { pathname: `/` });
    });
  };

  const handleCredentialResponse = (response) => {
    signIn(response).then((response) => {
      if (response.success) {
        history.replace(location?.state?.from || { pathname: `/` });
      }
    });
  };

  if (user || isLoggedIn) {
    history.replace("/");
    // navigate('/');
  }

  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     callback: handleCredentialResponse
  //   })

  //   google.accounts.id.renderButton(
  //     document.getElementById("gSigninButton"),
  //     { theme: 'outline', size: 'large' }
  //   )
  // }, []);

  const Glogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      signIn(codeResponse.email, codeResponse.id).then(() => {
        history.replace(location?.state?.from || { pathname: `/` });
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [Guser]);

  const logOut = () => {
    // googleLogout();
    setProfile(null);
  };

  return (
    <>
      <div className="userlogin">
        <div className="userLoginContainer">
          <div className="userloginLeft">
            <span className="logoadmin">
              <div className="logoadminbottom">
                <img src={logo} alt="" className="logo" />
                <img src={graphic} alt="" />
              </div>
            </span>
          </div>
          <div className="userLoginRight">
            <div className="pageHeaderLogin">
              <div className="pageHeaderText">
                <span className="pageHeaderTitle">Login</span>
                <span className="pageHeaderDescription">
                  Welcome back, please enter your details to login.
                </span>
              </div>
            </div>
            <form className="loginForm">
              <div className="loginItem">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>

              <div className="loginItem">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="xxxxxxxxxx"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />

                <div className="resetoptionsContainer">
                  {/* <div className="resetoptionsLeft"><input type="checkbox"></input> Remember me</div> */}

                  <div className="resetoptionsLeft">
                    <button
                      type="button"
                      className="forgotpasswordbtn"
                      onClick={openForgotPasswordPopup}
                    >
                      <input type="checkbox"></input> <span>Remember me</span>
                    </button>
                  </div>

                  <div className="resetoptionsRight">
                    <button
                      type="button"
                      className="forgotpasswordbtn"
                      onClick={openForgotPasswordPopup}
                    >
                      Forgot Password?
                    </button>
                  </div>
                </div>
                <button
                  disabled={username === "" && password === ""}
                  className="loginButton"
                  onClick={login}
                >
                  Login
                </button>
              </div>
            </form>
            {/* <div className="divider">
              <img src={or} alt="" />
            </div> */}
       


{/* <div class="google-btn">
  <div class="google-icon-wrapper">
    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
  </div>
  <p class="btn-text" onClick={() => Glogin()}>Login with Google</p>
</div> */}



            {/* <div className="resetoptionsRight">
              <Link type="submit" className="needsanaccount" to={"/signup"}>
                Dont have an account? <span>Sign Up</span>
              </Link>
            </div> */}

        
          </div>
        </div>

        <CustomPopup
          trigger={forgotPasswordPopup}
          setTrigger={() => closeForgotPasswordPopup()}
        >
          {step === 0 ? (
            <>
              <h3 className="popupTitle">Please enter your email address</h3>
              <input
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
                type="email"
                className="popupText"
                placeholder="Example: John@gmail.com"
              />
              <button
                disabled={emailAddress === ""}
                className="loginButton"
                onClick={emailAddress === "" ? () => { } : goToNextStep}
              >
                Next
              </button>
            </>
          ) : step === 1 ? (
            <>
              <h3 className="popupTitle">
                Please check your email, we have just sent you a one time pass
                code
              </h3>
              <input
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                type="text"
                className="popupText"
                placeholder="xxxxxx"
              />
              <button
                disabled={otp === ""}
                className="loginButton"
                onClick={otp === "" ? () => { } : verifyOtp}
              >
                Verify
              </button>
            </>
          ) : (
            ""
          )}
        </CustomPopup>
      </div>
    </>
  );
}

export default Login;
