import React from 'react'
import './support.css'

export default function Support() {
  return (
    <div>
      
    </div>
  )
}
