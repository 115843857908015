import "./app.css";
import Sidebar from "./components/sidebar/Sidebar";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import React, { useState, useEffect } from "react";

import Overview from "./components/pages/Overview/Overview";
import Users from "./components/pages/Users/Users";
import User from "./components/pages/User/User";
import NewUser from "./components/pages/NewUser/NewUser";
import Templates from "./components/pages/Templates/Templates";
// import Schedule from './components/pages/Schedule/Schedule';
import NewTemplate from "./components/pages/NewTemplate/NewTemplate";
import Template from "./components/pages/Template/Template";
import { useAppState } from "./state";
import Analytics from "./components/pages/Analytics/Analytics";
import Campaigns from "./components/pages/Campaigns/Campaigns";
import CampaignDetail from "./components/pages/CampaignDetail/CampaignDetail";
import Logs from "./components/pages/Logs/Logs";
import Channels from "./components/pages/Channels/Channels";
import NewChannel from "./components/pages/NewChannel/NewChannel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import AddFireWalk from "./components/pages/FireSafety/FireWalks/addFireWalk";
import BuildFireWalk from "./components/pages/FireSafety/FireWalks/build-statement";
import SMS from "./components/pages/SMS/sendSMS";
import sendSMS from "./components/pages/SMS/sendSMS";
import sendEmail from "./components/pages/Email/scheduleEmail";
import Chat from "./components/pages/Chat/Chat";
import scheduleEmail from "./components/pages/Email/scheduleEmail";
import scheduleSMS from "./components/pages/SMS/scheduleSMS";
import SendNow from "./components/pages/SMS/sendSMS";
import EmailNow from "./components/pages/Email/sendEmail";
import ScheduleSMS from "./components/pages/SMS/scheduleSMS";
import ScheduleEmail from "./components/pages/Email/scheduleEmail";
import SendNowChannel from "./components/pages/SMS-Channel/sendSMSChannel";
import BothSendSMSNowChannel from "./components/pages/SMS-Channel/sendBothChannel";
import ScheduleSMSChannel from "./components/pages/SMS-Channel/scheduleSMSChannel";
import BothScheduleSMSChannel from "./components/pages/SMS-Channel/scheduleBothChannel";
import EmailNowChannel from "./components/pages/EmailChannel/sendEmailChannel";
import BothEmailNowChannel from "./components/pages/EmailChannel/sendBothChannel";
import BothEmailScheduleChannel from "./components/pages/EmailChannel/scheduleBothChannel";
import ScheduleEmailChannel from "./components/pages/EmailChannel/scheduleEmailChannel";

import smsIcon from "../src/components/images/smsmainiconwhite.svg";
import voiceIcon from "../src/components/images/voice.svg";
import smssendnowIcon from "../src/components/images/smssubsendsms.svg";
import smsscheduleIcon from "../src/components/images/smssubschedulesms.svg";
import emailIcon from "../src/components/images/emailmainicon.svg";
import emailsendnowIcon from "../src/components/images/emailsendnow.svg";
import emailscheduleIcon from "../src/components/images/emailschedule.svg";
import archiveIcon from "../src/components/images/archive.svg";
import chatIcon from "../src/components/images/chatmainicon.svg";
import whatsappIcon from "../src/components/images/ri_whatsapp-fill.svg";
import userIcon from "../src/components/images/usermainicon.svg";
import analyticsIcon from "../src/components/images/analytivsnew.svg";
import activityIcon from "../src/components/images/activity.svg";
import overviewIcon from "../src/components/images/overviewmainicon.svg";
import templatesIcon from "../src/components/images/templateiconf.svg";
import contactsIcon from "../src/components/images/contactsiconf.svg";
import SubscriptionsIcon from "../src/components/images/pricingicon.svg";
import channelIcon from "../src/components/images/channels.svg";
import ListIcon from '@mui/icons-material/List';
import GroupIcon from '@mui/icons-material/Group';
import { Stripe } from "./components/Stripe";
import { StripeLoggedIn } from "./components/StripeLoggedIn";
import SingleChannel from "./components/pages/SingleChannel/Channel";
import SingleChannelAuth from "./components/pages/SingleChannelAuth/SingleChannelAuth";
import ChannelDetails from "./components/pages/SingleChannelDetails/ChannelDetails";
import Profile from "./components/pages/Profile/Profile";
import Feedback from "./components/pages/Feedback/Feedback";
import Settings from "./components/pages/Settings/Settings";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Tasks from "./components/pages/Tasks/Tasks";

import Support from "./components/pages/Support/Support";
import EmailCampaign from "./components/pages/EmailCampaign/EmailCampaign";
import WpUI from "./components/pages/WhatsappUI/WpUI";
import WhatsappCampaign from "./components/pages/WhatsappCampaign/Campaign";
import Contacts from "./components/pages/Contacts/Contacts";
import AllContacts from "./components/pages/Contacts/AllContacts";
import NewContact from "./components/pages/NewContact/NewContact";
import TimeContainer from "./components/common/TimeContainer";
import VoiceChannel from "./components/pages/VoiceChannel/VoiceChannel";
import CreateContactList from "./components/pages/Contacts/List/CreateContactList";
import ImportContacts from "./components/pages/Contacts/Import/ImportContacts";





const PrivateRoutesConfig = [
  {
    id: "overviewid",
    component: Overview,
    path: "/",
    title: "Overview",
    icon: <img src={overviewIcon} className="filter-red" />,
    exact: true,
    permission: ["superadmin", "admin", "ambassador"],
    tooltipContent: "Overview",
  },
  /*{
    id: "tasksid",
    component: Tasks,
    path: "/Tasks",
    title: "Tasks",
    icon: <AssignmentTurnedInOutlinedIcon />,
    exact: true,
    permission: ["superadmin", "admin", "ambassador"],
    tooltipContent: "Tasks",

  },*/
  // {
  //   id: "logsid",
  //   component: Logs,
  //   path: "/Logs",
  //   title: "User Activity Log",
  //   icon: <img src={activityIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin"],
  //   tooltipContent: "User Activity Log",
  // },
  {

    component: User,
    path: "/profile",
    title: "User Profile",
    permission: ["superadmin", "admin", "ambassador"],

  }, {

    component: Feedback,
    path: "/Feedback",
    title: "Leave Feedback",
    permission: ["superadmin", "admin", "ambassador"],

  }, {
    component: Support,
    path: "/Support",
    title: "Support & Help",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    id: "channelsid",
    component: Channels,
    path: "/Channels",
    title: "Channels",
    icon: <img src={channelIcon} className="filter-red" />,
    permission: ["superadmin", "admin"],
    tooltipContent: "Channels",
  },
  {
    id: "newchannels",
    component: NewChannel,
    path: "/newChannel",
    title: "New Channel",
    permission: ["superadmin", "admin"],
  },
  {
    component: SingleChannel,
    path: "/channel-edit/:channelId",
    title: "Edit Channel",
    permission: ["superadmin", "admin"],
  },
  {
    component: SingleChannelAuth,
    path: "/channel-edit-auth/:channelId",
    title: "Edit Channel Auth",
    permission: ["superadmin", "admin"],
  },
  {
    component: ChannelDetails,
    path: "/channel-details-edit/:channelId",
    title: "Edit Channel Details",
    permission: ["superadmin", "admin"],
  },
  {
    component: SingleChannel,
    path: "/channel-view/:channelId",
    title: "View Channel",
    permission: ["superadmin", "admin"],
  },
  {
    component: User,
    path: "/user-edit/:userId",
    title: "Edit User",
    permission: ["superadmin", "admin"],
  },
  {
    component: User,
    path: "/user-profile/:userId",
    title: "Edit User",
    permission: ["superadmin", "admin"],
  },
  {
    component: User,
    path: "/user-view/:userId",
    title: "View User",
    permission: ["superadmin", "admin"],
  },
  {
    component: NewUser,
    path: "/newUser/:userRole",
    title: "New User",
    permission: ["superadmin", "admin"],
  },
  {
    id: "sms-channel",
    title: "SMS Channel",
    icon: <img src={smsIcon} className="filter-red" />,
    permission: ["superadmin", "admin", "ambassador"],
    includedpaths: ["/sendSMSChannel", "/scheduleSMSChannel"],
    tooltipContent: "SMS",
    items: [
      {
        id: "sendSMS-channel",
        component: BothSendSMSNowChannel,
        path: "/sendSMSChannel",
        title: "Send SMS Channel",
        icon: <img src={smssendnowIcon} className="filter-white" />,
        tooltipContent: "Send SMS",
      },
      {
        id: "scheduleSMS-channel",
        component: BothScheduleSMSChannel,
        path: "/scheduleSMSChannel",
        title: "Schedule SMS Channel",
        icon: <img src={smsscheduleIcon} className="filter-white" />,
        tooltipContent: "Schedule SMS",
      },
    ],
  },
  {
    id: "email-channel",
    title: "Email Channel",
    icon: <img src={emailIcon} className="filter-red" />,
    permission: ["superadmin", "admin", "ambassador"],
    includedpaths: ["/sendEmailChannel", "/scheduleEmailChannel"],
    tooltipContent: "Email",
    items: [
      {
        id: "sendEmail-channel",
        component: BothEmailNowChannel,
        path: "/sendEmailChannel",
        title: "Send Email Channel",
        icon: <img src={emailsendnowIcon} className="filter-white" />,
        tooltipContent: "Send Emails",
      },
      {
        id: "scheduleEmail-channel",
        component: BothEmailScheduleChannel,
        path: "/scheduleEmailChannel",
        title: "Schedule Emails Channel",
        icon: <img src={emailscheduleIcon} className="filter-white" />,
        tooltipContent: "Schedule Emails",
      },
    ],
  },
  // {
  //   id: "sms",
  //   title: "SMS",
  //   icon: <img src={smsIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   includedpaths: ["/sendSMS", "/scheduleSMS"],
  //   items: [
  //     {
  //       id: "sendSMS",
  //       component: SendNow,
  //       path: "/sendSMS",
  //       title: "Send SMS",
  //       icon: <img src={smssendnowIcon} className="filter-white" />,
  //     },
  //     {
  //       id: "scheduleSMS",
  //       component: ScheduleSMS,
  //       path: "/scheduleSMS",
  //       title: "Schedule SMS",
  //       icon: <img src={smsscheduleIcon} className="filter-white" />,
  //     },
  //   ],
  // },
  // {
  //   id: "email",
  //   title: "Email",
  //   icon: <img src={emailIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   includedpaths: ["/sendEmail", "/scheduleEmail"],
  //   items: [
  //     {
  //       id: "sendEmail",
  //       component: EmailNow,
  //       path: "/sendEmail",
  //       title: "Send Email",
  //       icon: <img src={emailsendnowIcon} className="filter-white" />,
  //     },
  //     {
  //       id: "scheduleEmail",
  //       component: ScheduleEmail,
  //       path: "/scheduleEmail",
  //       title: "Schedule emails",
  //       icon: <img src={emailscheduleIcon} className="filter-white" />,
  //     },
  //   ],
  // },

  {
    id: "wp-channel",
    title: "WhatsApp Channel",
    icon: <img src={whatsappIcon} className="filter-red" />,
    permission: ["superadmin", "admin", "ambassador"],
    includedpaths: ["/whatsapp", "/whatsapp-archives"],
    tooltipContent: "WhatsApp Channel",
    items: [
      {
        id: "wpid",
        component: WpUI,
        path: "/whatsapp",
        title: "WhatsApp",
        icon: <img src={whatsappIcon} className="filter-white" />,
        tooltipContent: "WhatsApp",
      },
      {
        id: "wparchiveid",
        component: WpUI,
        path: "/whatsapp-archives",
        title: "WhatsApp Archives",
        icon: <img src={archiveIcon} className="filter-white" />,
        tooltipContent: "WhatsApp Archives",
      },
    ],
  },
  // {
  //   id: "voiceChannelId",
  //   component: VoiceChannel,
  //   path: "/voice-channel",
  //   title: "Voice Channel",
  //   icon: <img src={voiceIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   tooltipContent: "Voice Channel",
  // },
  // {
  //   id: "wpid",
  //   component: WpUI,
  //   path: "/whatsapp",
  //   title: "Whatsapp",
  //   icon: <img src={whatsappIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   tooltipContent: "Whatsapp",
  // },
  // {
  //   id: "wparchiveid",
  //   component: WpUI,
  //   path: "/whatsapp-archives",
  //   title: "Whatsapp archives",
  //   icon: <img src={whatsappIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   tooltipContent: "Whatsapp archives",
  // },

  // {
  //   id: "chatid",
  //   component: Chat,
  //   path: "/Chat",
  //   title: "Chat",
  //   icon: <img src={chatIcon} className="filter-red" />,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   tooltipContent: "Chat",
  // },
  // {
  //   id: "stripeid",
  //   component: StripeLoggedIn,
  //   path: "/plansloggedin",
  //   title: "Pricing",
  //   icon: <img src={SubscriptionsIcon} className="filter-red" />,
  //   exact: true,
  //   permission: ["superadmin", "admin", "ambassador"],
  //   tooltipContent: "Pricing",
  // },
  // {
  //   id: "stripeid",
  //   component: Stripe,
  //   path: "/plans",
  //   title: "Pricing",
  //   icon: <img src={SubscriptionsIcon} className="filter-red" />,
  //   exact: true,
  //   permission: ["superadmin", "admin"],
  //   tooltipContent: "Pricing",
  // },
  {
    id: "usersid",
    component: Users,
    path: "/users",
    title: "Users",
    icon: <img src={userIcon} className="filter-red" />,
    permission: ["superadmin", "admin"],
    tooltipContent: "Users",
  },
  {
    id: "analyticsid",
    component: Analytics,
    path: "/analytics",
    title: "Analytics",
    icon: <img src={analyticsIcon} className="filter-red" />,
    permission: ["superadmin", "admin", "ambassador"],
    tooltipContent: "Analytics",
  },
  {
    component: Campaigns,
    path: "/campaigns-view/:campaignId",
    title: "Analytics",
    permission: ["superadmin", "admin", "ambassador"],

  },
  {
    component: CampaignDetail,
    path: "/campaign-detail/:campaignId",
    title: "Analytics",
    permission: ["superadmin", "admin", "ambassador"],

  },
  {
    component: EmailCampaign,
    path: "/email-campaign/:campaignId",
    title: "Analytics",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    component: WhatsappCampaign,
    path: "/whatsapp-campaign/:campaignId",
    title: "Analytics",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    id: "templatesid",
    component: Templates,
    path: "/templates",
    title: "Templates",
    icon: <img src={templatesIcon} className="filter-red" />,
    permission: ["superadmin", "admin", "ambassador"],
    tooltipContent: "Templates",
  },
  {
    component: NewTemplate,
    path: "/newtemplate",
    title: "NewTemplate",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    component: Template,
    path: "/template-edit/:templateId",
    title: "Template",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    component: Template,
    path: "/template-view/:templateId",
    title: "Template",
    permission: ["superadmin", "admin", "ambassador"],
  },
  {
    id: "contactsid",
    component: Contacts,
    path: "/contacts",
    title: "Contacts",
    icon: <img src={contactsIcon} className="filter-red" />,
    //permission: ["superadmin", "admin", "ambassador"],
    permission: ["superadmin"],
    tooltipContent: "Contacts",
  },
  /*{
    id: "contact-channel",
    title: "Contacts",
    icon: <img src={contactsIcon} className="filter-red" />,
    permission: ["superadmin"],
    includedpaths: ["/contacts", "/allcontacts"],
    tooltipContent: "Contacts",
    items: [
      {
        id: "contactlistid",
        component: Contacts,
        path: "/contacts",
        title: "Contact Lists",
        icon: <ListIcon className="filter-white" />,
        tooltipContent: "Contact Lists",
      },
      {
        id: "allcontactsid",
        component: AllContacts,
        path: "/allcontacts",
        title: "All Contacts",
        icon: <GroupIcon className="filter-white" />,
        tooltipContent: "All Contacts",
      }
    ],
  },*/
  {
    id: "newcontact",
    component: NewContact,
    path: "/newContact",
    title: "New Contact",
    //permission: ["superadmin", "admin","ambassador"],
    permission: ["superadmin"],
  },
  {
    id: "createcontactlist",
    component: CreateContactList,
    path: "/create-contact-list",
    title: "Add New Contact List",
    //permission: ["superadmin", "admin","ambassador"],
    permission: ["superadmin"],
  },
  {
    id: "createcontactlist",
    component: CreateContactList,
    path: "/create-contactlist-by-parent/:parentType",
    title: "Add New Contact List",
    //permission: ["superadmin", "admin","ambassador"],
    permission: ["superadmin"],
  },
  {
    id: "editcontactlist",
    component: CreateContactList,
    path: "/edit-contact-list/:listId",
    title: "Edit List",
    permission: ["superadmin"],
  },
  {
    id: "editcontactlistparent",
    component: CreateContactList,
    path: "/edit-contactlist-parent/:parentType",
    title: "Update Contact Lists Parent Type",
    permission: ["superadmin"],
  },
  {
    id: "viewcontactlist",
    component: CreateContactList,
    path: "/view-contact-list/:listId",
    title: "View List",
    permission: ["superadmin"],
  },
  {
    id: "editcontact",
    component: NewContact,
    path: "/contact-edit/:id",
    title: "Edit Contact",
    permission: ["superadmin"],
  },
  {
    id: "ImportContactss",
    component: ImportContacts,
    path: "/contact/import",
    title: "Import Contacts",
    permission: ["superadmin"],
  },
  {
    component: Settings,
    path: "/Settings",
    title: "User Settings",
    permission: ["superadmin", "admin", "ambassador"],

  },
];

function MapAllowedRoutes({ routes, isAddNotFound }) {
  return (
    <Switch>
      {routes.map((route) => {
        const { items, ...rest } = route;

        // console.log(items)

        if (items && Array.isArray(items) && items.length > 0) {
          return items.map((subroute) => {
            // console.log(subroute)
            const {
              path,
              component: Component,
              children,
              title,
              permission,
              ...rest
            } = subroute;

            return (
              <Route {...rest} key={path} path={`${path}`}>
                <Component children={children} />
              </Route>
            );
          });
        } else {
          // console.log(route)
          const {
            path,
            component: Component,
            children,
            title,
            permission,
            ...rest
          } = route;

          return (
            <Route {...rest} key={path} path={`${path}`}>
              <Component children={children} />
            </Route>
          );
        }
      })}
      {/* {isAddNotFound && <Route><NotFound /></Route>} */}
      {isAddNotFound && (
        <Route>
          <Overview />
        </Route>
      )}{" "}
      {/*Temporary redirected to Overview page */}
    </Switch>
  );
}

function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

function getAllowedRoutes(routes, userRole) {
  return routes.filter(({ permission }) => {
    if (!permission) {
      return true;
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      const intersected = permission.filter((value) => value === userRole);
      return intersected.length;
    }
  });
}

function App() {
  const { user } = useAppState();
  const [open, setOpen] = React.useState(false);
  const match = useRouteMatch();
  let allowedRoutes = getAllowedRoutes(PrivateRoutesConfig, user.role);

  const datefunction = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  // const updateUserSubscription = async () => {
  //   if (user) {
  //     const [num] = user?.trial.split("-");
  //     const date = new Date(parseInt(num) * 1000);
  //     const currentDate = new Date();
  //     const checkdate = datefunction(date);
  //     const curDate = datefunction(currentDate);
  //     if (checkdate === curDate) {
  //       await fetch(process.env.REACT_APP_API_BASEURL + "/updateUser", {
  //         method: "POST",
  //         headers: {
  //           "content-type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           email: user?.email,
  //         }),
  //       });
  //     }
  //   }
  // };

  // const handleOnActive = () => setIsIdle(false)
  // const handleOnIdle = () => setIsIdle(false)

  let trialExpired = false
  if (user && !user.subscription_id && user.trial) {
    const eta = user.trial.split("-")[0]
    const countDownDate = new Date(parseInt(eta) * 1000).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (distance < 0) {
      trialExpired = true
      allowedRoutes = allowedRoutes.filter(v => v.path === "/plans")
    }
  }

  return (
    <div className="container">
      <Router history={createBrowserHistory({})}>
        <Switch>
          <Route path="">
            <Sidebar open={open} setOpen={setOpen} routes={allowedRoutes} path={match?.path} />
            <div className={open ? "main-custom-open" : "main-custom"}>
              <MapAllowedRoutes routes={allowedRoutes} isAddNotFound />
            </div>
          </Route>
        </Switch>
      </Router>
      <TimeContainer />
      <ToastContainer />
    </div>
  );
}

export default App;
