import React from 'react'
import axios from '../../helpers/request';
import './errortable.css'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { DeleteOutlined, EditOutlined, VisibilityOutlined, AddOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function CustomToolbar() {
  return (
    <span className='exportbutton'>
      <GridToolbarExport />
    </span>
  );
}

export default function ErrorTable({ campaignid }) {

  const [data, setData] = useState({
    data: [],
    total: 0
  });

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);

  const getSmsErrors = (campaignid) => {
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    };
    axios.get('/failed-message-analytic/' + campaignid, { params: searchParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }

  useEffect(() => {
    if (campaignid) {
      getSmsErrors(campaignid)
    }
  }, [campaignid, pageSize, pageNumber])

  const dynamicHeight = Math.min(data.length * 6 + 10, 80) + 'vh'

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    { field: 'errorcode', headerName: 'Error Code', width: 120, sortable: false },
    { field: 'errormessage', headerName: 'Error Message', flex: 1, sortable: false },
    {
      field: 'datetimesent', headerName: 'Date Sent', width: 170, type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value)
    },
    { field: 'fromnumber', headerName: 'From Number', width: 150, sortable: false },
    { field: 'tonumber', headerName: 'To Number', width: 150, sortable: false },
    { field: 'status', headerName: 'Status', width: 100, sortable: false },

  ];

  return (
    <div className='errorTable'>
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">SMS Errors</h3>

      </div>

      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}
