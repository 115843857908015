import { useState } from "react";
import axios from '../../../helpers/request';
import './sms.css'
import CustomPopup from '../../Popup/CustomPopup';
import { useAppState } from '../../../state';
import templatesIcon from "../../images/templates.svg";
import { useEffect } from "react";
import Cookies from "universal-cookie";


const PHONE_NUMBER_REGEX = new RegExp(/^\+[\d]+$/);

export default function WhatsappSMSChannel({updateChatList}) {
  const [campaign, setCampaign] = useState({
    contactNumber: '',
    messageBody: ''
  });
  const [channel, setChannel] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [sending, setSending] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState('');
  const { user: loggedUser } = useAppState();

  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");
  const getChannelList = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          axios.get(`/all-channels/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`).then(async (response) => {
            if (response.status === 200 && response.data) {
              console.log(response.data)
              setChannelList(response.data)
            } else {
              throw new Error(response?.error);
            }
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  useEffect(() => {
    getChannelList()
  }, [])

  const openPopup = (event) => {
    event.preventDefault();
    setData([]);
    setTemplate('')
    getTemplates();
    setButtonPopup(true);
  }

  const getTemplates = () => {
    let queryParams = {
      "companyId": loggedUser.added_by ? loggedUser.added_by : loggedUser.id
    }
    axios.get('/templates', { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        const templates = response.data;
        const smsTemplates = templates.filter((template) => template.type !== "email");
        setData(smsTemplates);
      } else {
        throw new Error(response?.error);
      }
    })
    .catch((err) => {
      console.log("errr", err);
    });;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCampaign(prevCampaign => ({
      ...prevCampaign,
      [name]: value
    }));
  }

  const applyTemplate = () => {
    const selectedTemplate = data.filter(temp => temp.id == template)
    if (selectedTemplate && selectedTemplate[0]) {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        messageBody: selectedTemplate[0].templatebody
      }));
    }
    setButtonPopup(false);
  }

  const resetForm = () => {
    setCampaign({
      contactNumber: '',
      messageBody: ''
    })
  }

  const isValidData = () => {
    const { contactNumber, messageBody } = campaign;
    let isValid = true;
    let message = '';
    if (messageBody === "") {
      isValid = false;
      message = "All fields are mandatory";
    } else {
      if (contactNumber === "") {
        isValid = false;
        message = "Please enter the contact number";
      } else if (!PHONE_NUMBER_REGEX.test(contactNumber)) {
        isValid = false;
        message = "Invalid phone number";
      }
    }
    return { isValid, message };
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();
    if (isValid) {
      setSending(true);
      let currentChannelData = channelList.filter(v => v.id == channel)[0]
      let { fromdata, sid, token } = currentChannelData
      axios.post('/send-wp-message-channel', { data: { ...campaign, fromdata, sid, token, companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id }, actionBy: loggedUser.fullName })
        .then(function (response) {
          setSending(false);
          resetForm();
          updateChatList()
          alert("Sent messages successfully");
        })
        .catch(function (error) {
          setSending(false);
          alert("Unable to process this request");
        });
    } else {
      alert(message);
    }
  }

  return (
    <div className=''>
      <div className="newUserItem">
        <label>Please select a channel</label>
        <select value={channel} className='popupDropdown' onChange={(event) => {
          setChannel(event.target.value)
          resetForm()
        }}>
          <option value="" disabled>Select channel</option>
          {channelList.filter(v => (v.type == "whatsapp")).map((chan) => <option key={chan.id} value={chan.id}>{chan.label}</option>)}
        </select>
      </div>
      {
        channel &&
        <>
          <form className="sendNowForm">
            <div className="sendNowItemContainer">
              <div className="sendNowItem">
                <label>Add Mobile Number <span className="mandatory">*</span></label>
                <input value={campaign.contactNumber} onChange={handleChange} name="contactNumber" type="text" placeholder='E.I64 format, example: +447123456789' className="contactsimage" />
              </div>
            </div>
            <div className="sendNowItem">
              <div className="templateButtonContainer">
                <label>Enter Whatsapp Text <span className="mandatory">*</span></label>
              </div>
              <textarea value={campaign.messageBody} onChange={handleChange} name="messageBody" placeholder='Enter the body of your SMS message here'></textarea>
              <div className="templateButtonContainer">
                <button className="templateElementButton mt-2" onClick={openPopup}>
                  <img src={templatesIcon} className="filter-red" />
                  <span className="templateButtonText">Select Template</span></button>
                <button disabled={sending} className="sendNowButton mt-2" onClick={handleSubmit}>Send Now</button>
              </div>
            </div>
          </form>
          <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h3 className='popupTitle'>Please select your message template</h3>
            <select value={template} className='popupDropdown' onChange={(event) => setTemplate(event.target.value)} >
              <option value="" disabled>Select Template</option>
              {data.map((template) => <option key={template.id} value={template.id}>{template.templatename}</option>)}
            </select>
            <button className="sendNowButton" disabled={template === ""} onClick={applyTemplate}>Apply</button>
          </CustomPopup>
        </>
      }
    </div>
  )
}